import { createReducer } from '@reduxjs/toolkit';

import {
    wallets
} from './actions'
import { Wallet } from './types';

export interface TransactionState {
    wallets: Array<Wallet>,
}

const initialState: TransactionState = {
    wallets: []
}

export default createReducer(initialState, builder =>
  builder
  .addCase(wallets, (state, action) => {
    const { wallets } = action.payload
    state.wallets = wallets
  })
);
