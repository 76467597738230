import { makeStyles, Theme } from '@material-ui/core';
import {defaultTheme} from "../../modules/theme/mainTheme";

export const useConnectWalletStyles = makeStyles<Theme>(theme => ({
  root: {
    minHeight: '100vh',
    padding: theme.spacing(9, 30.5),
  },
  welcome: {
  },
  welcomeHead: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 94,
    marginBottom: 56,
    '& > h3': {
      fontSize: 42,
      marginBottom: 24,
      color: theme.palette.text.primary,
      fontFamily: 'NeverMind-DemiBold'
    },
    '& > span': {
      fontSize: 18,
      color: theme.palette.text.secondary
    }
  },
  welcomeContent: {
    marginTop: theme.spacing(12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  walletItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '304px',
    height: '250px',
    background: '#1F1F1F',
    borderRadius: '30px',
    marginRight: 40,
    cursor: "pointer",
    '& > span': {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 600,
      marginTop: '34px',
      fontFamily: 'NeverMind-DemiBold'
    },
    '& > img': {
      transition: 'all 0.3s'
    },
    '&:hover > img': {
      width: '83px',
    }
  },
  homeWrapper: {},
  homeTitle: {
    fontSize: '28px',
    fontWeight: 600,
  },
  homeTop: {
    background: '#1F1F1F',
    borderRadius: '30px',
    padding: theme.spacing(9, 10),
    margin: theme.spacing(9, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }
  },
  homeTopLeft: {},
  homeTopRight: {},
  homeTopLeftItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: '70px',
    color: defaultTheme.palette.text.primary,
    '&:last-child': {
      margin: 0,
    },
    '& > span' : {
      fontSize: '16px',
      fontWeight: 600,
      marginBottom: '22px',
      color: defaultTheme.palette.text.secondary,
    },
    '& > div': {
      fontSize: '28px',
      fontWeight: 600,
      '& > span': {
        fontSize: '14px',
        fontWeight: 500,
        color: defaultTheme.palette.text.secondary,
      }
    }
  },
  homeTopRightItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '40px',
    cursor: 'pointer',
    '& > span': {
      fontSize: '14px',
      fontWeight: 600,
      marginTop: '8px',
      color: defaultTheme.palette.text.primary,
    },
    '& > img': {
      transition: 'all 0.3s',
    },
    '&:hover > img': {
      width: '51px',
    }
  },
  homeBottom: {},
  homeBottomTitle: {
    '& > sup': {
      fontSize: '16px',
    }
  },
  homeWallet: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  homeWalletItem: {
    background: 'linear-gradient(225.45deg, rgba(111, 119, 189, 0.22) 4.48%, rgba(111, 119, 189, 0.1) 107.71%)',
    borderRadius: '30px',
    padding: theme.spacing(9, 10),
    margin: theme.spacing(4, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    cursor: 'pointer'
  },
  homeWalletItemLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& > h3': {
      fontSize: '22px',
      fontWeight: 600,
      color: '#888686',
    },
    '& > span': {
      fontSize: '14px',
      marginTop: '7px',
      color: defaultTheme.palette.text.secondary,
    }
  },
  homeWalletItemCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginRight: '120px',
      '&:last-child': {
        margin: 0
      },
      '& > h3': {
        fontSize: '22px',
        fontWeight: 600,
        color: defaultTheme.palette.text.primary,
      },
      '& > span': {
        fontSize: '14px',
        marginTop: '7px',
        color: defaultTheme.palette.text.secondary,
      }
    }
  },
  homeWalletItemRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& > img': {
      cursor: "pointer",
      marginBottom: '10px',
      '&:last-child': {
        margin: 0
      },
    }
  }
}));
