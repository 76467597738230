import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const StakingIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 33 32">
        <g clip-path="url(#clip0_432_3659)">
            <g clip-path="url(#clip1_432_3659)">
                <path d="M27.2772 20.2667L28.8799 21.228C28.9788 21.2872 29.0606 21.371 29.1174 21.4713C29.1743 21.5715 29.2041 21.6848 29.2041 21.8C29.2041 21.9152 29.1743 22.0285 29.1174 22.1288C29.0606 22.229 28.9788 22.3128 28.8799 22.372L17.1866 29.388C16.9792 29.5126 16.7418 29.5784 16.4999 29.5784C16.258 29.5784 16.0206 29.5126 15.8132 29.388L4.11989 22.372C4.02102 22.3128 3.93918 22.229 3.88235 22.1288C3.82552 22.0285 3.79565 21.9152 3.79565 21.8C3.79565 21.6848 3.82552 21.5715 3.88235 21.4713C3.93918 21.371 4.02102 21.2872 4.11989 21.228L5.72255 20.2667L16.4999 26.7333L27.2772 20.2667ZM27.2772 14L28.8799 14.9613C28.9788 15.0205 29.0606 15.1043 29.1174 15.2046C29.1743 15.3048 29.2041 15.4181 29.2041 15.5333C29.2041 15.6486 29.1743 15.7618 29.1174 15.8621C29.0606 15.9623 28.9788 16.0462 28.8799 16.1053L16.4999 23.5333L4.11989 16.1053C4.02102 16.0462 3.93918 15.9623 3.88235 15.8621C3.82552 15.7618 3.79565 15.6486 3.79565 15.5333C3.79565 15.4181 3.82552 15.3048 3.88235 15.2046C3.93918 15.1043 4.02102 15.0205 4.11989 14.9613L5.72255 14L16.4999 20.4667L27.2772 14ZM17.1852 1.74534L28.8799 8.76134C28.9788 8.82054 29.0606 8.90434 29.1174 9.00459C29.1743 9.10484 29.2041 9.21811 29.2041 9.33334C29.2041 9.44858 29.1743 9.56185 29.1174 9.6621C29.0606 9.76235 28.9788 9.84615 28.8799 9.90534L16.4999 17.3333L4.11989 9.90534C4.02102 9.84615 3.93918 9.76235 3.88235 9.6621C3.82552 9.56185 3.79565 9.44858 3.79565 9.33334C3.79565 9.21811 3.82552 9.10484 3.88235 9.00459C3.93918 8.90434 4.02102 8.82054 4.11989 8.76134L15.8132 1.74534C16.0206 1.62075 16.258 1.55493 16.4999 1.55493C16.7418 1.55493 16.9792 1.62075 17.1866 1.74534H17.1852ZM16.4999 4.44268L8.34922 9.33334L16.4999 14.224L24.6506 9.33334L16.4999 4.44268Z" fill="currentColor"/>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_432_3659">
                <rect width="32" height="32" fill="currentColor" transform="translate(0.5)"/>
            </clipPath>
            <clipPath id="clip1_432_3659">
                <rect width="32" height="32" fill="currentColor" transform="translate(0.5)"/>
            </clipPath>
        </defs>
    </SvgIcon>
  );
};
