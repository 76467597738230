import React, {useState} from 'react'
import './index.scss'
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../../images/dialog_close.svg'
import {WalletItem} from "../../../pages/connectWallet";

export default function SelectWalletDialog(props) {
    const { handleClose, open, onOpenEditWallet } = props;

    return (
        <Dialog className="select-wallet-dialog" onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
            <div className="dialog-wrapper">
                <div>
                    <div className="dialog-header">
                        <h3>Select Wallet</h3>
                        <img width={24} height={24} src={CloseIcon} alt="" onClick={() => handleClose()}/>
                    </div>
                    <div className="dialog-content">
                        <div className="select-wallet-wrapper">
                            <WalletItem handleOpenEditWallet={() => onOpenEditWallet()}/>
                            <WalletItem handleOpenEditWallet={() => onOpenEditWallet()}/>
                            <WalletItem handleOpenEditWallet={() => onOpenEditWallet()}/>
                            <WalletItem handleOpenEditWallet={() => onOpenEditWallet()}/>
                            <WalletItem handleOpenEditWallet={() => onOpenEditWallet()}/>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
