import React, {useState} from 'react'
import './index.scss'
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../../images/dialog_close.svg'
import BackIcon from '../../../images/back_icon.svg'
import SuccessIcon from '../../../images/success_icon.svg'
import _ from "lodash";
import {InputField} from "../../InputField/InputField";
import {t} from "../../../modules/i18n/intl";
import Checkbox from '@material-ui/core/Checkbox';
import {NormalButton, PinkButton} from "../../Button";
import Remind from "../../Remind";
import classnames from 'classnames'

const DraggableSeed = (props) => {
    const {
        isDragging,
        index,
        word,
        selected,
        className,
        onClick,
        isOver,
        canDrop,
        seedIndex,
    } = props
    return (
        <div
            key={index}
            className={classnames(
                'notranslate create-home-dialog__seed-word',
                className,
                {
                    'create-wallet-dialog__seed-word--selected': selected,
                    'create-wallet-dialog__seed-word--dragging': isDragging,
                    'create-wallet-dialog__seed-word--empty': !word,
                    'create-wallet-dialog__seed-word--not-empty': word,
                    'create-wallet-dialog__seed-word--active-drop': !isOver && canDrop,
                    'create-wallet-dialog__seed-word--drop-hover': isOver && canDrop,
                },
            )}
            onClick={() => onClick(seedIndex)}
            data-testid={`draggable-seed-${selected ? 'selected-' : ''}${word}`}
        >
            {word}
        </div>
    )
}

export const PointComponent = (props) => {
    const { step, total } = props
    let str = []
    for (let i = 0; i < total; i++ ) {
        str.push(<span className={step === i ? 'select-step' : ''}></span>)
    }
    return str
}


export default function CreateWalletDialog(props) {
    const { handleClose, open } = props;
    const [step, setStep] = useState(0)
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [checked, setChecked] = React.useState(false);
    const [selectedSeedIndices, setSelectedSeedIndices] = React.useState([]);
    const [rightSeed, setRightSeed] = React.useState(false);
    const seedPhrase = 'verify wife cake slim topic enable wedding ball knock trouble skull rifle'
    const sortedSeedWords = (seedPhrase.split(' ') || []).sort()

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const handlePasswordChange = (value) => {
        setPassword(value);
    };
    const handleConfirmPasswordChange = (value) => {
        setConfirmPassword(value);
    };

    const handleDeselectSeedWord = (index) => {
        const updatedSeedIndices = selectedSeedIndices.filter(
            (i) => index !== i,
        )
        setSelectedSeedIndices(updatedSeedIndices)
        const selectedSeedWords = updatedSeedIndices.map(
            (i) => sortedSeedWords[i],
        )
        setRightSeed(seedPhrase === selectedSeedWords.join(' '))
    }

    const handleSelectSeedWord = (index) => {
        setSelectedSeedIndices([...selectedSeedIndices, index])
        const selectedSeedWords = [...selectedSeedIndices, index].map(
            (i) => sortedSeedWords[i],
        )
        setRightSeed(seedPhrase === selectedSeedWords.join(' '))
    }

    function insert(list, value, target, removeOld) {
        let nextList = [...list]

        if (typeof list[target] === 'number') {
            nextList = [...list.slice(0, target), value, ...list.slice(target)]
        }

        if (removeOld) {
            nextList = nextList.filter((seed, i) => {
                return seed !== value || i === target
            })
        }

        return nextList
    }

    const onDrop = (targetIndex) => {
        const { selectedSeedIndices, draggingSeedIndex } = this.state

        const indices = insert(
            selectedSeedIndices,
            draggingSeedIndex,
            targetIndex,
            true,
        )
        setSelectedSeedIndices(indices)
    }
    return (
        <Dialog className="create-wallet-dialog" onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
            <div className="dialog-wrapper">
                <div>
                    <div className="dialog-header">
                        {step !== 3 && step !== 0 ? <img
                            width={24}
                            height={24}
                            src={BackIcon}
                            alt=""
                            onClick={() => {
                                if (step > 0) {
                                    setStep(step - 1)
                                }
                            }}
                        />: <span></span>}
                        <div className="create-wallet-step-wrapper">
                            <PointComponent step={step} total={4}/>
                        </div>
                        <img width={24} height={24} src={CloseIcon} alt="" onClick={() => handleClose()}/>
                    </div>
                    {step === 0 && (
                        <div className="dialog-content-wrapper">
                            <div className="dialog-content">
                                <div className="create-wallet-header">Create a new wallet</div>
                                <InputField
                                    label='Set a password'
                                    value={password}
                                    placeHolder=''
                                    type="password"
                                    appendContent={(<span>Make sure to enter at least 8 and max 200 characters, including one upper-case letter, a symbol and a number</span>)}
                                    onChange={(e) => handlePasswordChange(e.target.value)}
                                />
                                <InputField
                                    label='Re-enter password'
                                    value={confirmPassword}
                                    placeHolder=''
                                    type="password"
                                    appendContent={(<div className="agree-wrapper">
                                        <Checkbox
                                            checked={checked}
                                            onChange={handleChange}
                                            color="default"
                                            inputProps={{ 'aria-label': 'checkbox with default color' }}
                                        />
                                        <span>I understand that I will need both the keystore file and the password to access my wallet. Once I have downloaded the file below, I will safely store it as well as the password</span>
                                    </div>)}
                                    onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                                />
                            </div>
                            <div className="dialog-footer">
                                <PinkButton
                                    disabled={!checked}
                                    onClick={() => setStep(1)}
                                > Download keystore file and continue </PinkButton>
                            </div>
                        </div>
                    )}
                    {step === 1 && (
                        <div className="dialog-content-wrapper">
                            <div className="dialog-content">
                                <div className="create-wallet-header">Back Up Your Mnemonic</div>
                                <div className="create-wallet-remind">
                                    <Remind content="Write down the  words in order. keep them somewhere safe" />
                                </div>
                                <div className="seed-words">
                                    {sortedSeedWords.map((item, index) => (
                                        <div className="seed-words-item" key={`${item}_${index}`}>
                                            <span>{index + 1}</span>
                                            <div>{item}</div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                            <div className="dialog-footer">
                                <PinkButton
                                    disabled={!checked}
                                    onClick={() => setStep(2)}
                                > I wrote down my recovery key </PinkButton>
                                <NormalButton
                                    disabled={!checked}
                                    onClick={() => {}}
                                > View your private key </NormalButton>
                            </div>
                        </div>
                    )}
                    {step === 2 && (
                        <div className="dialog-content-wrapper">
                            <div className="dialog-content">
                                <div className="create-wallet-header">Confirm Your Mnemonic</div>
                                <div className="create-wallet-remind">
                                    <Remind content="Please re-enter your words backup phrase" />
                                </div>
                                <div className="seed-words select-words-wrapper">
                                    {sortedSeedWords.map((word, index) => (
                                        <div
                                            className={selectedSeedIndices[index] !== undefined ? "select-words-item select-words-item-content" : "select-words-item"}
                                            onClick={() => handleDeselectSeedWord(selectedSeedIndices[index])}
                                        >
                                            <span>{index + 1}</span>
                                            <div>{selectedSeedIndices[index] !== undefined && sortedSeedWords[selectedSeedIndices[index]]}</div>
                                        </div>
                                    ))}
                                </div>
                                <div className="seed-words">
                                    {sortedSeedWords.map((word, index) => {
                                        const isSelected = selectedSeedIndices.includes(index)
                                        return (
                                            <DraggableSeed
                                                key={index}
                                                seedIndex={index}
                                                index={index}
                                                onDrop={onDrop}
                                                className="create-wallet-dialog__seed-word--sorted"
                                                selected={isSelected}
                                                onClick={() => {
                                                    if (isSelected) {
                                                        handleDeselectSeedWord(index)
                                                    } else {
                                                        handleSelectSeedWord(index)
                                                    }
                                                }}
                                                word={word}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="dialog-footer">
                                <PinkButton
                                    disabled={!rightSeed}
                                    onClick={() => setStep(3)}
                                > Verify </PinkButton>
                            </div>
                        </div>
                    )}
                    {step === 3 && (
                        <div>
                            <div className="dialog-content">
                                <div className="success-content">
                                    <img width={60} src={SuccessIcon} alt=""/>
                                    <h3>You're all set!</h3>
                                    <span>You have successfully created your wallet</span>
                                </div>
                            </div>
                            <div className="dialog-footer">
                                <NormalButton
                                    onClick={() => handleClose()}
                                > Access your wallet </NormalButton>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Dialog>
    )
}
