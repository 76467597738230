import {useWeb3React} from "@web3-react/core";
import {useEffect, useState} from 'react'
import { getPrice } from "utils/backendUtils";

export function usePrices() {
  const { account } = useWeb3React()
  const [priceLoading, setPriceLoading] = useState(false)
  const [ethPrice, setEthPrice] = useState(0)
  const [bnbPrice, setBnbPrice] = useState(0)
  const [skuPrice, setSkuPrice] = useState(0)
  
  useEffect(() => {
    if (!account) {
      return
    }
    setPriceLoading(true)
    getPrice().then(ret => {
      setEthPrice(ret?.['ethereum'].usd ?? 0)
      setBnbPrice(ret?.['binancecoin'].usd ?? 0)
      setSkuPrice(ret?.['sakura'].usd ?? 0)
    }).finally(() => {
      setPriceLoading(false)
    })
  }, [account])

  return {
    priceLoading,
    ethPrice,
    bnbPrice,
    skuPrice,
  }
}