import React, { useState } from 'react';
import { t } from 'modules/i18n/intl';
import { Button, makeStyles, Theme } from '@material-ui/core';
import { ToastTooltip } from 'components/ToastTooltip';
import { CorrectIcon } from 'modules/icons/CorrectIcon';
import { CopyIcon } from '../../modules/icons/CopyIcon';
import {PinkButton} from "../Button";

interface ICopyAddressProps {
  address: string;
}

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: theme.spacing(8, 0),
    overflow: 'hidden',
  },
  input: {
    alignItems: 'center',
    marginBottom: '32px',
    fontSize: '18px',
    fontWeight: 400,
    maxWidth: '282px',
    textAlign: 'center',
    wordBreak: "break-all"
  },
  button: {
    width: 80,
    borderRadius: 0,
  },
}));

export const CopyAddress = ({ address }: ICopyAddressProps) => {
  const classes = useStyles();

  const [copyTooltipOpen, setCopyTooltipOpen] = useState(false);
  const onCopyClicked = () => {
    navigator.clipboard.writeText(address);
    if (copyTooltipOpen) {
      return;
    }
    setCopyTooltipOpen(true);
    setTimeout(() => {
      setCopyTooltipOpen(false);
    }, 3 * 1000);
  };

  return (
    <div className={classes.root}>
      <div className={classes.input}>{address}</div>
      <ToastTooltip
        style={{ width: '100%' }}
        open={copyTooltipOpen}
        message="Copied!"
        icon={<CorrectIcon />}
        placement="top"
      >
        <PinkButton
          className={classes.button}
          onClick={onCopyClicked}
        >
          <CopyIcon/>
          {t('action.copy')}
        </PinkButton>
      </ToastTooltip>
    </div>
  );
};
