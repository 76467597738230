import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PrivateKeyIcon = (props: SvgIconProps) => {
  return (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_750_2469)">
            <path d="M29.2956 13.625H25.4214C25.0692 13.625 24.717 13.2841 24.717 12.9432V9.19321C24.717 8.8523 24.3648 8.51139 24.0126 8.51139H21.8994C21.5472 8.51139 21.195 8.8523 21.195 9.19321V13.1137C21.195 13.4546 20.8428 13.7955 20.4906 13.7955H17.3208C16.2642 10.0455 12.5661 7.31821 8.33965 8.17048C5.34594 8.68184 2.88053 10.8978 2.17613 13.7955C1.11953 18.5682 4.99374 23 9.74846 23C13.4466 23 16.6164 20.4432 17.3208 17.0341H29.2956C29.6478 17.0341 30 16.6932 30 16.3523V14.3068C30 13.9659 29.8239 13.625 29.2956 13.625ZM10.8051 19.4205C7.63525 20.2728 4.81764 17.5455 5.69814 14.4773C6.05034 13.1137 7.28305 11.9205 8.69185 11.5796C11.8617 10.7273 14.6793 13.4546 13.7988 16.5228C13.4466 17.8864 12.2139 19.0796 10.8051 19.4205Z" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_750_2469">
                <rect width="28" height="15" fill="currentColor" transform="translate(2 8)"/>
            </clipPath>
        </defs>
    </svg>
  );
};
