import { makeStyles, Theme } from '@material-ui/core';
import {defaultTheme} from "../../modules/theme/mainTheme";

export const useSendStyles = makeStyles<Theme>(theme => ({
  root: {
    minHeight: '100vh',
    padding: theme.spacing(7.5, 20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    marginTop: theme.spacing(10),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: '42px',
    fontWeight: 500,
    textAlign: 'center',
    color: defaultTheme.palette.text.primary,
  },
  formWrapper: {
    background: '#1F1F1F',
    backdropFilter: 'blur(100px)',
    borderRadius: '30px',
    padding: theme.spacing(8),
    marginTop: '36px',
    width: '598px',
  },
  formInput: {
    position: 'relative'
  },
  formItem: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '32px',
  },
  formItemTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '8px',
    '& div': {
      fontSize: '16px',
      color: defaultTheme.palette.text.primary
    },
    '& span': {
      fontSize: '12px',
      color: defaultTheme.palette.text.secondary
    }
  },
  formItemBottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'rgba(255, 255, 255, 0.05)',
    borderRadius: '8px',
    padding: theme.spacing(4.5),
    '& input': {
      background: 'transparent',
      border: 'none',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
      color: defaultTheme.palette.text.primary,
      cursor: 'pointer',
      outline: 'none',
      '&::placeholder': {
        color: '#888686',
        fontWeight: 400,
      }
    },
  },
  formItemLeft: {
  },
  formItemRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > span': {
      display: 'inline-block',
      padding: theme.spacing(0.5, 2),
      marginRight: theme.spacing(2),
      cursor: 'pointer',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      color: defaultTheme.palette.text.primary,
      background: '#1F1F1F',
      backdropFilter: 'blur(50.9146px)',
      borderRadius: '8px',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover': {
        background: 'none!important'
      },
      '& > div': {
        paddingBottom: '0',
        paddingTop: '0',
        fontSize: '18px!important',
        '& img': {
          width: '24px!important',
          height: '24px!important'
        }
      },
      '& svg': {
          top: 'calc(50% - 12px)!important',
          bottom: 'unset!important',
      }
    }
  },
  formFee: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '14px',
    color: defaultTheme.palette.text.secondary,
    marginBottom: '16px',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      '& div': {
        fontWeight: 600,
        lineHeight: '26px',
        color: defaultTheme.palette.text.primary,
      },
      '& spam': {
        fontSize: '12px!important',
      }
    }
  },
  spinnerWrapper: {
    '& > svg > path': {
      stroke: defaultTheme.palette.common.white,
    }
  }
}));
