import React, {useState} from 'react'
import './index.scss'
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../../images/dialog_close.svg'
import BackIcon from '../../../images/back_icon.svg'
import {InputField} from "../../InputField/InputField";
import {useWeb3React} from "@web3-react/core";
// import ArrowDown from '../../../images/arrow_down.svg'
// import {NormalButton, PinkButton} from "../../Button";
// import GasItem from '../../GasItem'
import { useAccountSkuBalanceByChain } from 'hooks/useAccountBalance';
import { PinkButton } from 'components/Button';
import { useBtnChild } from 'hooks/useBtnChild';
import { skuSendUtil } from 'utils/sakuraSendUtil';

export default function SendDialog(props) {
    const { handleClose, open, currentStep, to, amountNum, selectedNet, handleResult } = props;
    const [step, setStep] = useState(currentStep)
    const [amount, setAmount] = useState(amountNum??'')
    const [toAddress, setToAddress] = useState(to??'')
    const [option, setOption] = useState('')
    const [loading, setLoading] = useState(false)

    const { account, library } = useWeb3React()

    // const [walletPassword, setWalletPassword] = useState('')
    // const [showAdvance, setShowAdvance] = useState(false)
    // const [gasPrice, setGasPrice] = useState(0)
    // const [gasLimit, setGasLimit] = useState(0)

    const balanceObj = useAccountSkuBalanceByChain(selectedNet)
    const btnChild = useBtnChild(loading, 'Confirm')

    const handleAmountChange = (val) => {
        setAmount(val)
    }
    const handleToAddressChange = (val) => {
        setToAddress(val)
    }
    const handleOptionChange = (val) => {
        setOption(val)
    }
    // const handlePasswordChange = (val) => {
    //     setWalletPassword(val)
    // }

    const handleConfirm = async () => {
      setLoading(true)
      try {
        const txResult = await skuSendUtil.send(account, toAddress, amount, selectedNet, library)
        handleResult(txResult)
        handleClose()
      } catch (e) {
        console.log(e)
        if (e?.code === 4001) {
          return
        }
        handleResult({
          txStatus: false
        })
        handleClose()
      } finally {
        setLoading(false)
      }
    }

    return (
        <Dialog className="send-dialog" onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
            <div className="dialog-wrapper">
                <div>
                    {step !== 2 && (
                        <div className="dialog-header">
                            {step !== 0 && step !== 2 && currentStep !== 1 ? <img
                                width={24}
                                height={24}
                                src={BackIcon}
                                alt=""
                                onClick={() => {
                                    if (step > 0) {
                                        setStep(step - 1)
                                    }
                                }}
                            /> : <div style={{width: '24px'}}></div>}
                            <div className="send-title">
                                Send SKU
                            </div>
                            <img width={24} height={24} src={CloseIcon} alt="" onClick={() => handleClose()}/>
                        </div>
                    )}
                    {step === 0 && (
                        <div className="dialog-content-wrapper">
                            <div className="dialog-content">
                                <InputField
                                    label='Amount'
                                    value={amount}
                                    placeHolder=''
                                    type="text"
                                    rightContent={<span style={{color: '#888686'}}>Entire Balance</span>}
                                    onChange={(e) => handleAmountChange(e.target.value)}
                                />
                                <InputField
                                    label='Send To (address or domain)'
                                    value={toAddress}
                                    placeHolder=''
                                    type="text"
                                    onChange={(e) => handleToAddressChange(e.target.value)}
                                />
                                <InputField
                                    label='Memo (optional)'
                                    value={option}
                                    placeHolder=''
                                    type="text"
                                    onChange={(e) => handleOptionChange(e.target.value)}
                                />
                            </div>
                            <div className="dialog-footer">
                                <PinkButton
                                    margin="-24px 0 0"
                                    onClick={() => setStep(1)}
                                >  Continue </PinkButton>
                            </div>
                        </div>
                    )}
                    {step === 1 && (
                        <div className="dialog-content-wrapper">
                            <div className="dialog-content">
                                <div className="dialog-content-row">
                                    <span>Send To</span>
                                    <div>{toAddress}</div>
                                </div>
                                <div className="dialog-content-row">
                                    <span>Send From</span>
                                    <div>{`${account} (${balanceObj.formatedBalance} SKU, Wallet 3 )`}</div>
                                </div>
                                <div className="dialog-content-row">
                                    <span>Amount</span>
                                    <div>{amount}</div>
                                </div>
                                {/* <InputField
                                    label='Please enter your wallet password to send the transaction'
                                    value={walletPassword}
                                    placeHolder=''
                                    type="password"
                                    appendContent='Max Fee: 0.0141784271184 SKU'
                                    onChange={(e) => handlePasswordChange(e.target.value)}
                                />
                                <div className="advanced-wrapper">
                                    <h3>
                                        Advanced Functions
                                        <img onClick={() => setShowAdvance(true)} src={ArrowDown} alt=""/>
                                    </h3>
                                    {showAdvance && (
                                        <div>
                                            <GasItem
                                                text='Gas Price (GWEI)'
                                                value={gasPrice}
                                                onChange={(val) => setGasPrice(val)}
                                            />
                                            <GasItem
                                                text='Gas Limit'
                                                value={gasLimit}
                                                onChange={(val) => setGasLimit(val)}
                                            />
                                        </div>
                                    )}
                                </div> */}
                            </div>
                            <div className="dialog-footer">
                                <PinkButton
                                    disabled={loading}
                                    onClick={() => handleConfirm()}
                                >{btnChild}</PinkButton>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Dialog>
    )
}
