import React, { useState, useEffect } from 'react';
import { useStakingStyles } from './useStakingStyles';
import { useQuery } from '@apollo/client';
import {t} from "../../modules/i18n/intl";
import SakuraIcon from '../../images/sakura_logo.svg'
import UnknownIcon from '../../images/unknown_logo.svg'
import {AddIcon} from '../../modules/icons/AddIcon'
import Header from "../../components/Header/Header";
import {NormalButton, PinkButton} from "../../components/Button";
import DelegateDialog from "../../components/Dialog/DelegateDialog";
import LockDelegateDialog from "../../components/Dialog/LockDelegateDialog";
import ClaimRewardsDialog from "../../components/Dialog/ClaimRewardsDialog";
import UnDelegateDialog from "../../components/Dialog/UnDelegateDialog";
import {useWeb3React} from "@web3-react/core";
import { BigNumber as BN } from "bignumber.js";
import {shortenText} from "../../utils/helper";
import {formatBalance} from "@polkadot/util";
import SuccessDialog from "../../components/Dialog/SuccessDialog";
import { getValidatorsFromServer } from 'utils/backendUtils';
import { FETCH_DELEGATIONS_BY_ADDRESS } from 'utils/subgraph';
import { getDateFormat } from 'utils/timeUtils';
import WithdrawListComponent from './withdrawList/withdrawList';
import { CLAIM_OPERATIONS } from 'constants/base';

export default function StakingComponent(props) {
  const { onOpenAddressCode, onOpenConnectWallet } = props
  const classes = useStakingStyles();
  const { account } = useWeb3React()
  const [selectTab, setSelectTab] = useState(1);
  const [currentValidator, setCurrentValidator] = useState();
  const [openDelegateDialog, setOpenDelegateDialog] = useState(false);
  const [openClaimRewardsDialog, setOpenClaimRewardsDialog] = useState(false);
  const [openLockDelegateDialog, setOpenLockDelegateDialog] = useState(false);
  const [openUnDelegateDialog, setOpenUnDelegateDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [successTitle, setSuccessTitle] = useState('');
  const [successContent, setSuccessContent] = useState('');
  const [delegations, setDelegations] = useState([]);
  const [validators, setValidators] = useState([]);
  const [curDelegation, setCurDelegation] = useState()
  const [operation, setOperation] = useState('')
  // const Delegations = useQuery(FETCH_STAKERS);
  const AccountDelegations = useQuery(FETCH_DELEGATIONS_BY_ADDRESS, {
    variables: {address: account},
    fetchPolicy: 'cache-and-network',
    pollInterval: 15 * 1000
  });

  const getValidators = async () => {
      try {
        const data = await getValidatorsFromServer()
        if (data) {
            const arr = data.filter(item => item.isValidator && item.isActive).map((item, index) => {
                const id = new BN(item.id).toFixed()
                return {
                    ...item,
                    id,
                    name: id <= 4 ? `SakuraNode${id}` : 'Unknown',
                    icon: id <= 4 ? SakuraIcon : UnknownIcon,
                    stake: formatBalance(new BN(item.stake).toFixed(), { forceUnit: 'SKU', withSi: true }, 18).split(' ')[0].split('.')[0],
                    delegatedMe: formatBalance(new BN(item.delegatedMe).toFixed(), { forceUnit: 'SKU', withSi: true }, 18).split(' ')[0].split('.')[0],
                    totalStake: formatBalance(new BN(item.totalStake).toFixed(), { forceUnit: 'SKU', withSi: true }, 18).split(' ')[0].split('.')[0],
                }
            })
            setValidators(arr)
        }
      } catch (e) {
          console.log(e)
      }
  }

  useEffect(() => {
      getValidators()
  }, [account]);

  const getDelegations = (data) => {
    if (data) {
      const arr = data.map(item => {
        const validatorId = new BN(item.toStakerId).toNumber()
        const createdTime = new BN(item.createdTime).times(1000).toNumber()
        const amount = formatBalance(new BN(item.amountDelegated).toFixed(), { forceUnit: 'SKU', withSi: true }, 18).split(' ')[0].split('.')[0]
        const pendingRewardsForDisplay = formatBalance(new BN(item.pendingRewards.amount).toFixed(), { forceUnit: 'SKU', withSi: true }, 18).split(' ')[0]
        const claimedRewardForDisplay = formatBalance(new BN(item.claimedReward).toFixed(), { forceUnit: 'SKU', withSi: true }, 18).split(' ')[0]
        const lockEndTime = new BN(item.lockedUntil).times(1000).toNumber()
        const pendingWithdrawSum = item.withdrawRequests.map(x => x.amount).reduce((prv, cur) => {
          return new BN(prv).plus(new BN(cur))
        }, new BN(0))
        const pendingWithdrawForDisplay = formatBalance(pendingWithdrawSum.toFixed(), { forceUnit: 'SKU', withSi: true }, 18).split(' ')[0].split('.')[0]

        return {
          ...item,
          validatorId,
          validatorName: validatorId <= 4 ? `SakuraNode${validatorId}, ${validatorId}` : 'Unknown',
          delegateDate: getDateFormat(createdTime),
          delegatedAmount: amount,
          pendingRewardsForDisplay,
          claimedRewardForDisplay,
          validatorIcon: validatorId <= 4 ? SakuraIcon : UnknownIcon,
          endDateForDisplay: getDateFormat(lockEndTime),
          lockEndTime: !item.isDelegationLocked ? 0 : lockEndTime,
          pendingWithdrawForDisplay,
        }
      })
      setDelegations(arr)
    }
  }

  const handleSuccess = (title, content) => {
      setOpenSuccessDialog(true)
      setSuccessTitle(title)
      setSuccessContent(content)
      getValidators()
      AccountDelegations.refetch()
  }

  const handleWithdrawSuccess = () => {
    getValidators()
    AccountDelegations.refetch()
  }

  // useEffect(() => {
  //   if (Delegations?.data) {
  //     console.log(Delegations.data)
  //   }
  // }, [Delegations?.data])

  useEffect(() => {
    if (AccountDelegations.data) {
      getDelegations(AccountDelegations.data.delegationsByAddress.edges.map(item => item.delegation))
    }
  }, [AccountDelegations.data, AccountDelegations.data?.delegationsByAddress.edges])

  return (
    <div className={classes.root}>
      <Header title="Staking" onOpenConnectWallet={onOpenConnectWallet} onOpenAddressCode={onOpenAddressCode} />
      <div className={classes.content}>
          <div className={classes.tabs}>
              <div className={selectTab === 0 ? 'selected' : ''} onClick={() => setSelectTab(0)}>Wallet Delegations <sup>{delegations.length}</sup></div>
              <div className={selectTab === 1 ? 'selected' : ''} onClick={() => setSelectTab(1)}> Validators <sup>{validators.length}</sup></div>
          </div>
          {selectTab === 0 ? (
              <div className={classes.delegationWrapper}>
                {
                  delegations && delegations.length > 0 ? (
                      delegations.map((item, index) => (
                          <div className={classes.delegationItem} key={`${index}_delegations`}>
                              <div className={classes.delegationInfoWrapper}>
                                  <div className={classes.delegationItemLeft}>
                                      <img width={52} src={item.validatorIcon} alt=""/>
                                      <span>{item.validatorName}</span>
                                  </div>
                                  <div className={classes.delegationItemRight}>
                                      <div className={classes.delegationItemRightTop}>
                                          <div>
                                              <span>Delegation Time</span>
                                              <div>{item.delegateDate}</div>
                                          </div>
                                          <div>
                                              <span>Delegated</span>
                                              <div>{item.delegatedAmount} SKU</div>
                                          </div>
                                          <div>
                                              <span>Pending Rewards</span>
                                              <div>{item.pendingRewardsForDisplay} SKU</div>
                                          </div>
                                          <div>
                                              <span>Claimed Rewards</span>
                                              <div>{item.claimedRewardForDisplay} SKU</div>
                                          </div>
                                          <div>
                                              <span>Pending withdraw</span>
                                              <div>{item.pendingWithdrawForDisplay} SKU </div>
                                          </div>
                                          <div>
                                              <span>Unlock Date</span>
                                              <div>{item.lockEndTime > 0 ? item.endDateForDisplay : '_'}</div>
                                          </div>
                                      </div>
                                      <div className={classes.delegationItemRightBtns}>
                                          {
                                              new BN(item.pendingRewards.amount).gt(0) &&
                                              <NormalButton onClick={() => {
                                                  setOperation(CLAIM_OPERATIONS.CLAIM)
                                                  setCurDelegation(item)
                                                  setOpenClaimRewardsDialog(true)
                                              }} height="32px"> Claim Rewards </NormalButton>
                                          }
                                          {
                                              new BN(item.pendingRewards.amount).gt(0) &&
                                              <NormalButton onClick={() => {
                                                  setOperation(CLAIM_OPERATIONS.RESTAKE)
                                                  setCurDelegation(item)
                                                  setOpenClaimRewardsDialog(true)
                                              }} height="32px"> Claim & Restake </NormalButton>
                                          }
                                          <NormalButton onClick={() => {
                                              setCurDelegation(item)
                                              setOpenUnDelegateDialog(true)
                                          }} height="32px"> Undelegate </NormalButton>
                                          {
                                              !item.isDelegationLocked &&
                                              <NormalButton onClick={() => {
                                                  setCurDelegation(item)
                                                  setOpenLockDelegateDialog(true)
                                              }} height="32px">Lock Delegation</NormalButton>
                                          }
                                      </div>
                                  </div>
                              </div>
                              {
                                  item.withdrawRequests.length > 0 &&
                                  <div className={classes.withdrawListWrapper}>
                                      <WithdrawListComponent
                                          withdrawList={item.withdrawRequests}
                                          handleWithdrawSuccess={handleWithdrawSuccess}
                                          validatorId={item.validatorId}
                                      />
                                  </div>
                              }
                          </div>
                      ))
                  ) : (
                      <div className={classes.delegationNone}>
                          No items
                      </div>
                  )
                }
                <PinkButton
                    width="fit-content"
                    margin="28px 0 0"
                    onClick={() => setSelectTab(1)}
                >
                    <AddIcon/>
                    Add delegation
                </PinkButton>
              </div>
          ) : (
              <div className={classes.tableWrapper}>
                  <div className={classes.tableHeader}>
                      <span>Name</span>
                      <div>ID</div>
                      <div>Self-Staked (SKU)</div>
                      <div>Delegated (SKU)</div>
                      <div>Total Staked (SKU)</div>
                      <div style={{textAlign: 'right'}}>Action</div>
                  </div>
                  {validators && validators.length > 0 ? (
                      validators.map((item, index) => (
                          <div
                              className={classes.tableItem}
                              key={`${index}_validators`}
                          >
                              <div className={classes.tableItemName}>
                                  <img width={24} src={item.icon} alt=""/>
                                  <span>{item.name}</span>
                              </div>
                              <div>{item.id}</div>
                              <div>{item.stake}</div>
                              <div>{item.delegatedMe}</div>
                              <div>{item.totalStake}</div>
                              <div className={classes.tableItemAction}>
                                  <NormalButton
                                      onClick={() => {
                                          setCurrentValidator(item)
                                          setOpenDelegateDialog(true)
                                      }}
                                      height="32px"
                                  >Delegate</NormalButton>
                              </div>
                          </div>
                      ))
                  ) : (
                      <div className={classes.delegationNone}>
                          No items
                      </div>
                  )}
              </div>
          )}
      </div>
        {openDelegateDialog && <DelegateDialog
            open={openDelegateDialog}
            validator={currentValidator}
            handleClose={() => {
                setOpenDelegateDialog(false)
                // setSelectTab(0)
            }}
            handleSuccess={handleSuccess}
        />}
        {openClaimRewardsDialog && <ClaimRewardsDialog
            operation={operation}
            delegation={curDelegation}
            handleSuccess={handleSuccess}
            open={openClaimRewardsDialog}
            handleClose={() => setOpenClaimRewardsDialog(false)}
        />}
        {openUnDelegateDialog && <UnDelegateDialog
            delegation={curDelegation}
            open={openUnDelegateDialog}
            handleClose={() => setOpenUnDelegateDialog(false)}
            handleSuccess={handleSuccess}
        />}
        {openLockDelegateDialog && <LockDelegateDialog
            delegation={curDelegation}
            open={openLockDelegateDialog}
            handleClose={() => setOpenLockDelegateDialog(false)}
            handleSuccess={handleSuccess}
        />}
        {openSuccessDialog && <SuccessDialog
            open={openSuccessDialog}
            title={successTitle}
            content={successContent}
            handleClose={() => setOpenSuccessDialog(false)}
        />}
    </div>
  );
}
