import { makeStyles, Theme } from '@material-ui/core';
import {defaultTheme} from "../../modules/theme/mainTheme";

export const useReceiveStyles = makeStyles<Theme>(theme => ({
  root: {
    minHeight: '100vh',
    padding: theme.spacing(7.5, 20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    marginTop: theme.spacing(20),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  codeWrapper: {
    borderRadius: '8px',
    background: '#fff',
    padding: '8px'
  },
  title: {
    fontSize: '42px',
    fontWeight: 500,
    textAlign: 'center',
    color: defaultTheme.palette.text.primary,
    marginBottom: '32px'
  },
}));
