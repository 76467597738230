import {useWeb3React} from "@web3-react/core";
import './index.scss'
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../../images/dialog_close.svg'
import Remind from "../../Remind";
import QRCode from "qrcode-react";

export default function AddressCodeDialog(props) {
    const { handleClose, open } = props;
    const { account } = useWeb3React()

    return (
        <Dialog className="address-code-dialog" onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
            <div className="dialog-wrapper">
                <div>
                    <div className="dialog-header">
                        <h3>Address</h3>
                        <img width={24} height={24} src={CloseIcon} alt="" onClick={() => handleClose()}/>
                    </div>
                    <div className="dialog-content">
                        <div className="address">{account}</div>
                        <div className="code-wrapper">
                            <QRCode value={account} size={256} />
                        </div>
                        <Remind content="Warning: Use this address to receive Opera SKU only. If you are receiving SKU-ERC20 you need to use a different address!" />
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
