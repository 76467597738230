import {useWeb3React} from "@web3-react/core";
import React, {useEffect, useState} from 'react'
import { format, getSkuBalanceByChain } from "utils/balanceUtils";

export function useAccountSkuBalanceByChain(selectedChain) {
  const { account } = useWeb3React()
  const [balance, setBalance] = useState('')
  const [formatedBalance, setFormatedBalance] = useState('')
  const [balanceLoading, setBalanceLoading] = useState(false)
  useEffect(() => {
    setBalanceLoading(true)
    getSkuBalanceByChain(account, selectedChain).then((data) => {
      setBalance(data)
      setFormatedBalance(format(data))
    }).finally(() => {
      setBalanceLoading(false)
    })
  }, [account, selectedChain])

  return {
    balance,
    formatedBalance,
    balanceLoading
  }
}