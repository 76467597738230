import { makeStyles, Theme } from '@material-ui/core';
import { Flex } from 'rebass';
import {defaultTheme} from "../../../modules/theme/mainTheme";

export const useTheStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '20px',
  },
  itemList: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  withdrawItemWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginBottom: '20px',
    '&:last-child': {
      margin: '0!important'
    }
  },

  withdrawItemInfo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },

  withdrawInfoRight: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: defaultTheme.palette.text.primary,
    marginRight: '200px',
    display: 'flex',
    flexDirection: 'column',
    '&>div>span': {
      fontSize: '12px',
      marginLeft: '7px',
      color: defaultTheme.palette.text.secondary,
    }
  },

  historyWrapper: {
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      '& span': {
        color: defaultTheme.palette.text.primary,
      },
      '& $historySvg': {
        '& path': {
          fill: 'white'
        }
      }
    },
  },

  historySvg: {
  },

  withdrawBtnWrapper: {
    width: '23%'
  }
}));
