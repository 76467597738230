import { makeStyles, Theme } from '@material-ui/core';
import {defaultTheme} from "../../modules/theme/mainTheme";

export const useBridgeStyles = makeStyles<Theme>(theme => ({
  root: {
    minHeight: '100vh',
    padding: theme.spacing(7.5, 20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    marginTop: theme.spacing(10),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: '42px',
    fontWeight: 500,
    textAlign: 'center',
    color: defaultTheme.palette.text.primary,
  },
  formWrapper: {
    background: '#1F1F1F',
    backdropFilter: 'blur(100px)',
    borderRadius: '30px',
    padding: theme.spacing(8),
    marginTop: '36px',
    width: '598px',
  },
  formInput: {
    position: 'relative'
  },
  changeIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: '-20px',
    marginTop: '-20px',
    cursor: 'pointer'
  },
  formItem: {
    background: 'rgba(255, 255, 255, 0.05)',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2.5, 4.5, 2.5, 2.5),
    marginBottom: '32px',
    '& span': {
      fontSize: '12px',
      color: defaultTheme.palette.text.secondary
    }
  },
  formItemLeft: {
    display: 'flex',
    alignItems: 'center',
    '& > img': {
      marginRight: '12px'
    },
    '& > div': {
      position: 'relative',
      '& > span': {
        position: 'absolute',
        left: '62px',
        top: '20px'
      },
    },
  },
  formItemRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '& > input': {
      background: 'transparent',
      border: 'none',
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '32px',
      textAlign: 'right',
      color: defaultTheme.palette.text.primary,
      cursor: 'pointer',
      outline: 'none',
      '&::placeholder': {
        color: '#888686',
      }
    }
  },
  formRecipient: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'rgba(255, 255, 255, 0.05)',
    borderRadius: '20px',
    padding: theme.spacing(5, 4.5),
    fontSize: '12px',
    color: defaultTheme.palette.text.secondary,
    marginBottom: '32px',
    '& > div': {
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '26px',
      color: defaultTheme.palette.text.primary,
    }
  }
}));
