import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
} from "@apollo/client";
import React, { Suspense, useEffect } from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import { AppBase } from 'modules/layout/AppBase/AppBase';
import FirstPage from './pages/firstPage';
import {useWeb3React} from "@web3-react/core";
import {getProviderByWalletName, WALLET_NAME_CLV} from "constants/base";
import {getCurrentWallet, setCurrentWallet} from "./utils/LocalstorageUtils";
import { GRAPH_URL } from "constants/base";

export default function App() {
  const { account, activate } = useWeb3React()
  useEffect(() => {
    if (!account) {
      const currentWallet = getCurrentWallet()
      try {
        const wallet = getProviderByWalletName(currentWallet.name)
        if (wallet?.connector) {
          if (wallet.name === WALLET_NAME_CLV && !window.clover) {
            let n = 0
            let interval = setInterval(() => {
              n = n + 1
              if (n > 4) {
                clearInterval(interval)
              }
              if (wallet?.connector && window.clover) {
                clearInterval(interval)
                activate(wallet?.connector, async (error) => {
                  // error handle
                  console.log('error:', error)
                })
              }
            }, 500)
          } else {
            activate(wallet.connector, async (error) => {
              // error handle
              console.log('error1:', error)
            })
          }
        } else {
          setCurrentWallet({})
        }
      } catch (e) {

      }
    }
  }, [account]);

  const client = new ApolloClient({
    cache: new InMemoryCache({ addTypename: true }),
    uri: GRAPH_URL,
    connectToDevTools: true,
  });

  return (
    <Suspense fallback={null}>
      <ApolloProvider client={client}>
        <AppBase>
          <HashRouter>
            <Switch>
              <Route strict path="/" component={FirstPage} />
            </Switch>
          </HashRouter>
        </AppBase>
      </ApolloProvider>
    </Suspense>
  );
}
