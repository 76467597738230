import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const AddIcon = (props: SvgIconProps) => {
  return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="19.95" y="10.9517" width="2.55" height="15.45" transform="rotate(90 19.95 10.9517)" fill="currentColor"/>
          <rect x="13.5" y="19.9502" width="2.55" height="15.45" transform="rotate(-180 13.5 19.9502)" fill="currentColor"/>
      </svg>
  );
};
