import React, { useEffect, useState } from 'react';
import { isAddress } from "@ethersproject/address";
import { BigNumber as BN } from "bignumber.js";
import { useSendStyles } from './useSendStyles';
import Header from "../../components/Header/Header";
import {PinkButton} from "../../components/Button";
import {SelectNetwork} from "../../components/SelectNetwork";
import {OPTIONS, SAKURA_NETWORK} from "constants/base";
import SendDialog from "../../components/Dialog/SendDialog";
import { useAccountSkuBalanceByChain } from 'hooks/useAccountBalance';
import SuccessDialog from 'components/Dialog/SuccessDialog';
import { useEstimateFee } from 'hooks/useEstimateFee';
import { Spinner } from 'modules/common/components/Spinner';
import { INPUT_NUMBER_REGEX } from 'constants/regex';

export default function SendComponent(props) {
    const { onOpenAddressCode, onOpenConnectWallet } = props
    const classes = useSendStyles();
    const [fromNet, setFromNet] = useState(OPTIONS[0])
    const [amount, setAmount] = useState('')
    const [address, setAddress] = useState('')
    const [openSendDialog, setOpenSendDialog] = React.useState(false);
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('')
    const [dialogContent, setDialogContent] = useState('')
    const [txSuccess, setTxSuccess] = useState(true)
    const [amountErr, setAmountErr] = useState('')
    const [addressErr, setAddressErr] = useState('')
    const [addrChanged, setAddrChanged] = useState(false)

    const balanceObj = useAccountSkuBalanceByChain(fromNet)
    const feeObj = useEstimateFee(fromNet)

    useEffect(() => {
      if (new BN(amount).gt(new BN(balanceObj?.balance ?? '0'))) {
        setAmountErr('Insufficient balance')
      } else {
        setAmountErr('')
      }
    }, [balanceObj, amount])

    useEffect(() => {
      if (!addrChanged) {
        return
      }
      if (!isAddress(address)) {
        setAddressErr('Invalid Address')
      } else {
        setAddressErr('')
      }
    }, [address, addrChanged])

    const handleChangeNet = (type, net) => {
      setFromNet(net)
    }

    const handleChangeAmount = (amount) => {
      if (amount !== '' && !INPUT_NUMBER_REGEX.test(amount)) {
        return
      }
      setAmount(amount)
    }

    const handleChangeAddress = (address) => {
      setAddress(address)
      setAddrChanged(true)
    }

    const handleResult = (result) => {
      if (result.txStatus) {
        setDialogTitle('Success')
        setDialogContent(result.txHash)
      } else {
        setDialogTitle('Failed')
      }
      setTxSuccess(result.txStatus)
      setOpenSuccessDialog(true)
    }

    const handleMax = () => {
      if (balanceObj.balanceLoading) {
        return
      }

      if (fromNet.chainId === SAKURA_NETWORK.chainId) {
        if (feeObj.feeLoading || feeObj.gasInUsd === '0') {
          return
        }
        setAmount(new BN(balanceObj.balance).div(new BN(10).pow(18)).minus(new BN(feeObj.gasInToken)).toFixed())
      } else {
        setAmount(new BN(balanceObj.balance).div(new BN(10).pow(18)).toFixed())
      }
    }

    const isValid = () => {
      if (addressErr !== '' || amountErr !== '') {
        return false
      }

      if (!amount || !address) {
        return false
      }

      return true
    }
  return (
    <div className={classes.root}>
        <Header title="Send" onOpenConnectWallet={onOpenConnectWallet} onOpenAddressCode={onOpenAddressCode} />
        <div className={classes.content}>
          <div className={classes.formWrapper}>
              <div className={classes.formInput}>
                  <div className={classes.formItem}>
                      <div className={classes.formItemTop}>
                          <div>Amount</div>
                          <span>{`Balance: ${balanceObj.formatedBalance} SKU`}</span>
                      </div>
                      <div className={classes.formItemBottom}>
                          <div className={classes.formItemLeft}>
                              <input
                                  onChange={(e) => handleChangeAmount(e.target.value)}
                                  placeholder='Enter an amount'
                                  type="text"
                                  value={amount}
                              />
                          </div>
                          <div className={classes.formItemRight}>
                              <span onClick={handleMax}>Max</span>
                              <SelectNetwork
                                  selectedNet={fromNet}
                                  changeNet={(net) => handleChangeNet('from', net)}
                              />
                          </div>
                      </div>
                      <div style={{color: '#FD335E'}}>{amountErr}</div>
                  </div>
                  <div className={classes.formItem}>
                      <div className={classes.formItemTop}>
                          <div>Send To</div>
                      </div>
                      <div className={classes.formItemBottom}>
                          <input
                              style={{width: '100%'}}
                              onChange={(e) => handleChangeAddress(e.target.value)}
                              placeholder='Input a Sakura address'
                              type="text"
                          />
                      </div>
                      <div style={{color: '#FD335E'}}>{addressErr}</div>
                  </div>
              </div>
              <div className={classes.formFee}>
                  <span>Estimated Fees</span>
                  {
                    (feeObj.feeLoading || feeObj.gasInUsd === '0')? 
                      <div className={classes.spinnerWrapper}>
                        <Spinner size={16}/>
                      </div> :
                      <div>
                          <div>{`${feeObj?.gasInToken ?? '-'} SKU`}</div>
                          <span>{`$ ${feeObj?.gasInUsd ?? '-'}`}</span>
                      </div>
                  }
              </div>
              <PinkButton 
                disabled={!isValid()}
                onClick={() => setOpenSendDialog(true)}>
                  Continue
              </PinkButton>
          </div>
      </div>
        {openSendDialog && <SendDialog
            open={openSendDialog}
            to={address}
            amountNum={amount}
            selectedNet={fromNet}
            currentStep={1}
            handleResult={handleResult}
            handleClose={() => setOpenSendDialog(false)}
        />}
        {openSuccessDialog && <SuccessDialog
            open={openSuccessDialog}
            title={dialogTitle}
            content={dialogContent}
            fail={!txSuccess}
            handleClose={() => setOpenSuccessDialog(false)}
        />}
    </div>
  );
}
