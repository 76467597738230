import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CodeIcon = (props: SvgIconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_305_43042)">
            <path opacity="0.9" fill-rule="evenodd" clip-rule="evenodd" d="M0 7.27285H7.2727V0H0V7.27285ZM1.45445 1.45445H5.8181V5.8181H1.45445V1.45445ZM0 15.9999H7.2727V8.7271H0V15.9999ZM1.45445 10.1817H5.8181V14.5342H1.45445V10.1817ZM8.7271 7.27285V0H16V7.27285H8.7271ZM10.1818 5.8181H14.5454V1.45445H10.1818V5.8181Z" fill="currentColor"/>
            <path opacity="0.7" fill-rule="evenodd" clip-rule="evenodd" d="M4.36356 2.90899H2.90906V4.36364H4.36356V2.90894V2.90899ZM13.0909 10.1817H14.5454V8.72704H15.9999V13.0908H11.6361V11.6361H10.1818V15.9998H8.72711V8.72704H13.0909V10.1817ZM2.90906 11.6361H4.36356V13.0908H2.90906V11.6361ZM11.6361 14.5454H13.091V15.9999H11.6361V14.5454ZM16 14.5454H14.5452V15.9999H16V14.5454ZM11.6361 2.90899H13.091V4.36364H11.6361V2.90894V2.90899Z" fill="currentColor"/>
        </g>
        <rect x="0.5" y="0.5" width="15" height="15" stroke="currentColor"/>
        <defs>
            <clipPath id="clip0_305_43042">
                <rect width="16" height="16" fill="white"/>
            </clipPath>
        </defs>
    </svg>
  );
};
