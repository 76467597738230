import React, { useState } from 'react';
import { useBridgeStyles } from './useBridgeStyles';
import Header from "../../components/Header/Header";
import {PinkButton} from "../../components/Button";
import ChangeIcon from "../../images/change.svg";
import {SelectNetwork} from "../../components/SelectNetwork";
import {OPTIONS} from "constants/base";
import SendDialog from "../../components/Dialog/SendDialog";

const FormItem = (props) => {
    const {network, text, handleChangeNet, handleChangeAmount} = props
    const classes = useBridgeStyles();
    return (
        <div className={classes.formItem}>
            <div className={classes.formItemLeft}>
                <div>
                    <span>{text}</span>
                    <SelectNetwork
                        selectedNet={network}
                        changeNet={(net) => handleChangeNet('from', net)}
                    />
                </div>
            </div>
            <div className={classes.formItemRight}>
                <span>{text === 'To' ? `${network.ticker} Token` : 'Balance: 11 SKU'}</span>
                <input onChange={(e) => handleChangeAmount(e.target.value)} placeholder='0.0' type="text"/>
            </div>
        </div>
    )
}

export default function BridgeComponent(props) {
    const { onOpenAddressCode, onOpenConnectWallet } = props
    const classes = useBridgeStyles();
    const [fromNet, setFromNet] = useState(OPTIONS[0])
    const [toNet, setToNet] = useState(OPTIONS[2])
    const [amount, setAmount] = useState('')
    const [openSendDialog, setOpenSendDialog] = React.useState(false);

    const findNet = (net) => {
        if (net.chainId === OPTIONS[2].chainId) {
            return OPTIONS[0]
        } else {
            return OPTIONS[2]
        }
    }
    const handleChangeNet = (type, net) => {
        if (type === 'from') {
            if (toNet.chainId === net.chainId || (toNet.chainId !== OPTIONS[2].chainId && net.chainId !== OPTIONS[2].chainId)) {
                setToNet(findNet(net))
            }
            setFromNet(net)
        } else {
            if (fromNet.chainId === net.chainId || (fromNet.chainId !== OPTIONS[2].chainId && net.chainId !== OPTIONS[2].chainId)) {
                setFromNet(findNet(net))
            }
            setToNet(net)
        }
    }
    const handleChangeAmount = (amount) => {
        setAmount(amount)
    }
  return (
    <div className={classes.root}>
        <Header title="Bridge" onOpenConnectWallet={onOpenConnectWallet} onOpenAddressCode={onOpenAddressCode} />
        <div className={classes.content}>
          <div className={classes.formWrapper}>
              <div className={classes.formInput}>
                  <FormItem
                      network={fromNet}
                      text="From"
                      amount={amount}
                      handleChangeNet={handleChangeNet}
                      handleChangeAmount={handleChangeAmount}
                  />
                  <img width={40} className={classes.changeIcon} src={ChangeIcon} alt=""/>
                  <FormItem
                      network={toNet}
                      text="To"
                      amount={amount}
                      handleChangeNet={handleChangeNet}
                  />
              </div>
              <div className={classes.formRecipient}>
                  <span>Recipient</span>
                  <div>0x7c07ASD90FSFAafffq00992273afFebf25</div>
              </div>
              <PinkButton onClick={() => setOpenSendDialog(true)}>Continue</PinkButton>
          </div>
      </div>
        {openSendDialog && <SendDialog
            open={openSendDialog}
            to='0x7c07ASD90FSFAafffq00992273afFebf25'
            amountNum={amount}
            currentStep={1}
            handleClose={() => setOpenSendDialog(false)}
        />}
    </div>
  );
}
