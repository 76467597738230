import styled from 'styled-components'
import { Button as RebassButton } from 'rebass/styled-components'

export const NormalButton = styled(RebassButton)<{
  width?: string
  height?: string
  margin?: string
  disabled?: boolean
}>`
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : '52px')};
  min-width: fit-content;
  white-space: nowrap;
  padding: 0 24px;
  background: none;
  border: 2px solid #ffffff;
  border-radius: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  svg {
    width: 20px;
  }
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    color: ${({ disabled }) => !disabled && '#000000'};
    background: ${({ disabled }) => !disabled && '#FFFFFF'};
  }

  &:disabled {
    cursor: unset;
    color: #FFFFFF;
    border: 2px solid #FFFFFF;
    opacity: 0.5;
  }
`
export const PinkButton = styled(RebassButton)<{
  width?: string
  height?: string
  margin?: string
  disabled?: boolean
}>`
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : '52px')};
  min-width: fit-content;
  white-space: nowrap;
  background: rgba(255, 255, 255, 0.2);
  padding: 0 24px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  svg {
    width: 20px;
  }
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    color: ${({ disabled }) => !disabled && '#000000'};
    background: ${({ disabled }) => !disabled && '#FFFFFF'};
  }

  &:disabled {
    cursor: unset;
    color: #000000;
    background: rgba(255, 255, 255, 0.5);
  }
`
