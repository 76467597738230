import Web3 from "web3"
import { LOCK_MAX_DURATION, LOCK_MIN_DURATION, SAKURA_NETWORK, SFC_ADDRESS, WITHDRAW_DURATION } from "./base"

const abi = require("./SFC.json")

class ContractConstants {
  lockMinTime = LOCK_MIN_DURATION
  lockMaxTime = LOCK_MAX_DURATION
  withdrawTime = WITHDRAW_DURATION
  
  init() {
    const web3 = new Web3(SAKURA_NETWORK.rpcUrl)
    const contract = new web3.eth.Contract(abi, SFC_ADDRESS)
    Promise.all([
      contract.methods.minLockupDuration().call(),
      contract.methods.maxLockupDuration().call(),
      contract.methods.withdrawalPeriodTime().call()
    ]).then(ret => {
      this.lockMinTime = ret[0]
      this.lockMaxTime = ret[1]
      this.withdrawTime = ret[2]
    }).catch(e => {
      console.log(e)
    })
  }
}

export const contractConstants = new ContractConstants()

contractConstants.init()

