import { Web3Provider } from '@ethersproject/providers'
import { ChainInfo } from 'constants/base'

export function getNetworkLibrary(provider: Web3Provider): Web3Provider {
  return provider
}

export const addWalletNetwork = async (provider: any, fromChain: ChainInfo) => {
  if (fromChain?.chainId && provider) {
    try {
      await provider.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: fromChain?.chainId,
            chainName: fromChain?.nickname,
            nativeCurrency: {
                name: fromChain?.ticker,
                symbol: fromChain?.ticker,
                decimals: 18,
            },
            rpcUrls: [fromChain?.rpcUrl]
          },
        ]})
        return true
    } catch(e) {
      return false
    }
  } else {
    return true
  }
}

export const changeWalletNetwork = async (provider: any, fromChain: ChainInfo, currentChainId: number) => {
  if (fromChain?.chainId && currentChainId !== parseInt(fromChain?.chainId) && provider) {
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: fromChain?.chainId }], // chainId must be in hexadecimal numbers
      });
      return true
    } catch (error: any) {
      if (error.code === 4902 || (error?.startsWith && error?.startsWith('Unrecognized chain ID'))) {
        return await addWalletNetwork(provider, fromChain)
      }
    }
  } else {
    return true
  }
}
