import React, {useEffect, useState} from 'react'
import './index.scss'
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../../images/dialog_close.svg'
import {InputField} from "../../InputField/InputField";
import {PinkButton} from "../../Button";
import {getCurrentWallet} from "../../../utils/LocalstorageUtils";
import {skuUtil} from "../../../utils/sakuraUtil";
import { BigNumber as BN } from "bignumber.js";
import BackIcon from "../../../images/back_icon.svg";
import {useWeb3React} from "@web3-react/core";
import { useBtnChild } from 'hooks/useBtnChild';
import { useAccountSkuBalanceByChain } from 'hooks/useAccountBalance';
import { SAKURA_NETWORK } from 'constants/base';
import Web3 from 'web3';

export default function DelegateDialog(props) {
    const { handleClose, validator, open, handleSuccess } = props;
    const { account, library } = useWeb3React()
    const [amount, setAmount] = useState('');
    const [amountError, setAmountError] = useState('');
    const [isContinue, setIsContinue] = useState(false);
    // const [walletPassword, setWalletPassword] = useState('')
    const [loading, setLoading] = useState(false)

    const btnChild = useBtnChild(loading, 'Delegate')
    const balanceObj = useAccountSkuBalanceByChain(SAKURA_NETWORK)

    const currentWallet = getCurrentWallet()

    useEffect(() => {
      if (balanceObj.balance) {
        if (new BN(balanceObj.balance).lte(0)) {
          setAmountError('You have no balance left')
        }
      }
    }, [balanceObj])


    const handleAmountChange = (val) => {
        setAmount(val)
        if (new BN(Web3.utils.toWei(val)).gt(new BN(balanceObj.balance))) {
            setAmountError('Insufficient balance')
        } else {
            setAmountError('')
        }
    }

    const isVaild = () => {
        if (!amount || amountError) {
            return false
        }
        return true
    }

    const handleDelegate = async () => {
      setLoading(true)
      try {
        const txResult = await skuUtil.delegate(account, amount, validator.id, library)
        handleClose()
        handleSuccess('Delegation Lock Successful', txResult.txHash)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    return (
        <Dialog className="delegate-dialog" onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
            <div className="dialog-wrapper">
                {!isContinue ? (
                    <div>
                        <div className="dialog-header">
                            <h3>Delegate SKU</h3>
                            <img width={24} height={24} src={CloseIcon} alt="" onClick={() => handleClose()}/>
                        </div>
                        <div className="dialog-content">
                            <InputField
                                label='Amount'
                                value={amount}
                                placeHolder=''
                                type="text"
                                errorInfo={amountError}
                                rightContent={<span
                                    style={{color: '#888686'}}> Entire Balance {balanceObj.formatedBalance} SKU </span>}
                                onChange={(e) => handleAmountChange(e.target.value)}
                            />
                            <div className="dialog-content-validator">
                                <span>Validator</span>
                                <div>{validator.name}, {validator.id}</div>
                            </div>
                        </div>
                        <div className="dialog-footer">
                            <PinkButton
                                disabled={!isVaild()}
                                onClick={() => setIsContinue(true)}
                            >Continue</PinkButton>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="dialog-header">
                            <img
                                width={24}
                                height={24}
                                src={BackIcon}
                                alt=""
                                onClick={() => {
                                    setIsContinue(false)
                                }}
                            />
                            <h3>Delegate SKU - Confirmation</h3>
                            <img width={24} height={24} src={CloseIcon} alt="" onClick={() => handleClose()}/>
                        </div>
                        <div className="dialog-content-wrapper">
                            <div className="dialog-content">
                                <div className="dialog-content-row">
                                    <span>Validator</span>
                                    <div>{validator.name}, {validator.id}</div>
                                </div>
                                <div className="dialog-content-row">
                                    <span>Send From</span>
                                    <div>{account} ( {balanceObj.balance} SKU, {currentWallet.name} )</div>
                                </div>
                                <div className="dialog-content-row">
                                    <span>Amount</span>
                                    <div>{amount} SKU</div>
                                </div>
                                {/* <InputField
                                    label='Please enter your wallet password to bridge the transaction'
                                    value={walletPassword}
                                    placeHolder=''
                                    type="password"
                                    appendContent='Max Fee: 0.0141784271184 SKU'
                                    // onChange={(e) => handlePasswordChange(e.target.value)}
                                /> */}
                            </div>
                            <div className="dialog-footer" style={{marginTop: '0'}}>
                                <PinkButton
                                  disabled={loading}
                                  onClick={() => handleDelegate()}
                                >{btnChild}</PinkButton>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Dialog>
    )
}
