import { BigNumber as BN } from "bignumber.js";
import Web3 from 'web3'
import { formatBalance } from '@polkadot/util'

import { BigNumber } from "bignumber.js";
import {ChainInfo, OPTIONS, SAKURA_NETWORK} from "constants/base";
// import { ethCrossChain, bscCrossChain } from "./crossChainUtils";
const abi = require("human-standard-token-abi")

export type TokenType = {
    id: number,
    name: string,
    logo?: string
}
export type SerializableBigNum = {
    base: string,
    bigNum: string
}
export type TokenAmount = {
    tokenType: TokenType,
    amount: string, // TODO: remove and use amountBN instead
    amountBN: SerializableBigNum
}

export function trimEnd0(value: string): string {
  const n = new BN(value, 10)

  if (n.eq(0)) {
    return '0'
  }
  const afterTrim = value.replace(/0+$/,'')
  if (afterTrim.endsWith(".")) {
    return afterTrim + "0"
  }

  return afterTrim
}

export const getWeb3 = (library: any) => new Web3(library.provider)

export async function getSkuBalance(address: string) {
  if (!address) {
    return '0'
  }
  const web3 = new Web3(new Web3.providers.HttpProvider(OPTIONS[2].rpcUrl))
  let balance = await web3.eth.getBalance(address);
  balance = new BigNumber(balance).toFixed(0)
  return balance
  // const ethBalance = formatBalance(balance.toString(), { forceUnit: 'SKU', withSi: true }, 18);
  // return ethBalance.split(' ')[0].replace(/,/g, '').split('.')[0];
}

export async function getSkuBalanceByChain(address: string, selectedChain: ChainInfo) {
  if (!address) {
    return '0'
  }
  const web3 = new Web3(new Web3.providers.HttpProvider(selectedChain.rpcUrl))
  if (selectedChain.chainId === SAKURA_NETWORK.chainId) {
    let balance = await web3.eth.getBalance(address);
    balance = new BigNumber(balance).toFixed(0)
    return balance
  }

  const tokenContract = new web3.eth.Contract(abi, selectedChain.tokenAddress)
  let balance = await tokenContract.methods.balanceOf(address).call();
  balance = new BigNumber(balance).toFixed(0)
  return balance
}

export function format(balance: string) {
  return formatBalance(balance.toString(), { forceUnit: 'SKU', withSi: true }, 18).split(' ')[0].replace(/,/g, '').split('.')[0]
}

export function formatWithFp(balance: string) {
  const number = new BigNumber(balance).div(new BigNumber(10).pow(18)).toNumber()
  if (number < 0.00000001) {
    return '0'
  }
  if (number < 0.0000001) {
    return number.toFixed(8)
  }
  if (number < 0.000001) {
    return number.toFixed(7)
  }
  if (number < 0.00001) {
    return number.toFixed(6)
  }
  if (number < 0.0001) {
    return number.toFixed(5)
  }
  return formatBalance(balance.toString(), { forceUnit: 'SKU', withSi: true }, 18).split(' ')[0].replace(/,/g, '')
}

export function getContractAtAddress(tokenAddress: string, rpcUrl: string) {
    const web3 = new Web3(new Web3.providers.HttpProvider(rpcUrl))
    const contract = new web3.eth.Contract(abi, tokenAddress)
    return contract
}

export async function getErc20Balance(tokenAddress: string, address: string, rpcUrl: string) {
    const contract = getContractAtAddress(tokenAddress, rpcUrl)
    try {
        const fun = contract.methods['balanceOf']
        const result = await fun(address).call()
        const amount = result / Math.pow(10, 18);
        return amount
    } catch (error) {
        console.log(
            `balance() call at address ${address} resulted in error:`,
            error,
        )
        return undefined
    }
}

export async function getGasPrice(web3: Web3) {
  return await web3.eth.getGasPrice()
}

export async function getGasLimit(web3: Web3, selectedChain: ChainInfo, toAddress: string) {
  if (selectedChain.chainId === SAKURA_NETWORK.chainId) {
    return await web3.eth.estimateGas({
      from: toAddress,
      to: toAddress,
      value: web3.utils.toWei('0')
    })
  } else {
    const contract = new web3.eth.Contract(abi, selectedChain.tokenAddress)
    return await contract.methods.transfer(toAddress, web3.utils.toWei('0')).estimateGas({from: toAddress})
  }
}
