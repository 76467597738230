import axios from "axios";
import { SAKURA_NETWORK_RPC_URL } from "constants/base";

export const getValidatorsFromServer = async () => {
  const data = {
    method: "sfc_getValidators",
    params: ["0x1"],
    id: 1,
    jsonrpc: "2.0"
  }
  const resp = await axios.post(SAKURA_NETWORK_RPC_URL, data,
      {
          headers: {'Content-Type': "application/json"}
      }
  )

  return resp.data.result
}

export async function getPrice() {
  try {
    const resp = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=binancecoin,ethereum,sakura&vs_currencies=usd')
    return resp.data
  } catch(e) {
    console.log(e)
  }
}
