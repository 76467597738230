import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const EyeCloseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
        <path d="M6.22806 12.5214L4.94072 12.1761L5.46539 10.2167C4.68004 9.92709 3.95014 9.50498 3.30739 8.96874L1.87206 10.4047L0.928724 9.4614L2.36472 8.02607C1.55411 7.05524 1.00945 5.89058 0.784058 4.64607L2.09606 4.40674C2.60206 7.20807 5.05272 9.3334 8.00006 9.3334C10.9467 9.3334 13.3981 7.20807 13.9041 4.40674L15.2161 4.6454C14.991 5.89008 14.4465 7.05498 13.6361 8.02607L15.0714 9.4614L14.1281 10.4047L12.6927 8.96874C12.05 9.50498 11.3201 9.92709 10.5347 10.2167L11.0594 12.1767L9.77206 12.5214L9.24672 10.5614C8.42163 10.7028 7.57849 10.7028 6.75339 10.5614L6.22806 12.5214Z" fill="currentColor"/>
    </SvgIcon>
  );
};
