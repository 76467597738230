import React, {useState} from 'react'
import './index.scss'
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../../images/dialog_close.svg'
import {PinkButton} from "../../Button";
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Remind from "../../Remind";
import BackIcon from "../../../images/back_icon.svg";
import {useWeb3React} from "@web3-react/core";
import { useBtnChild } from 'hooks/useBtnChild';
import { DAY_DURATION, LOCK_MAX_DAY, LOCK_MIN_DAY } from 'constants/base';
import { getDateFormatWithWeek } from 'utils/timeUtils';
import { skuUtil } from 'utils/sakuraUtil';

const marks = [
  {
      value: 0,
      label: '14 Days'
  },
  {
      value: 100,
      label: '365 Days'
  },
];
const StyleSlider = withStyles({
  root: {
      padding: '15px 0 0',
      width: '99.8%',
      marginTop: '32px',
      '& > span.MuiSlider-markLabel[data-index="0"]': {
          left: '5%!important'
      },
      '& > span.MuiSlider-markLabel[data-index="1"]': {
          left: '94%!important'
      },
  },
  thumb: {
      height: '22px!important',
      width: '22px!important',
      color: '#FF5995',
      marginTop: '-9px!important'
  },
  markLabel: {
      marginTop: 3,
      color: '#77828C!important',
      fontFamily: 'Gilroy-Medium!important',
      background: 'transparent',
      fontSize: '14px',
      top: '-24px',
      // left: '5%!important'
  },
  track: {
      height: 3,
      borderRadius: 2,
      color: '#FF5995',
  },
  rail: {
      height: 3,
      opacity: 0.5,
      backgroundColor: '#77828C',
      borderRadius: 2
  },
  mark: {
      backgroundColor: '#888686',
      width: '1px',
      height: '13px',
      top: '3px'
  },
})(Slider);

const DEFAULT_DAY = 100
export default function LockDelegateDialog(props) {
    const { handleClose, open, handleSuccess, delegation } = props;
    const [isContinue, setIsContinue] = useState(false);
    const [loading, setLoading] = useState(false)
    const [days, setDays] = useState(100)
    const [slideValue, setSlideValue] = useState((DEFAULT_DAY - LOCK_MIN_DAY)/(LOCK_MAX_DAY - LOCK_MIN_DAY) * 100)

    const [endDate, setEndDate] = useState(getDateFormatWithWeek(Date.now() + DEFAULT_DAY * DAY_DURATION * 1000))
    const { account, library } = useWeb3React()
    const btnChild = useBtnChild(loading, 'Lock')

    const handleAction = async () => {
      setLoading(true)
      try {
        const txResult = await skuUtil.lock(account, delegation.amountDelegated, delegation.validatorId, days * DAY_DURATION, library)
        handleClose()
        handleSuccess(`Delegation Lock Successful`, txResult.txHash)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    const handleChange = (event, newValue) => {
      const d = Math.floor(LOCK_MIN_DAY + newValue / 100 * (LOCK_MAX_DAY - LOCK_MIN_DAY))
      setDays(d)
      setEndDate(getDateFormatWithWeek(Date.now() + d * DAY_DURATION * 1000))
      setSlideValue(newValue)
    };

    return (
        <Dialog className="lock-delegate-dialog" onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
            <div className="dialog-wrapper">
                {!isContinue ?  (
                    <div>
                        <div className="dialog-header">
                            <h3>Lock Delegation</h3>
                            <img width={24} height={24} src={CloseIcon} alt="" onClick={() => handleClose()}/>
                        </div>
                        <div className="dialog-content">
                            <div className="lock-delegate-remind">
                                <Remind content={`Delegation will be locked until ${endDate}`} />
                            </div>
                            <div className="lock-delegate-title">{days}<span>days</span></div>
                            <StyleSlider
                                defaultValue={slideValue}
                                step={0.3}
                                value={slideValue}
                                onChange={handleChange}
                                aria-labelledby="discrete-slider-restrict"
                                valueLabelDisplay="off"
                                marks={marks}
                            />
                        </div>
                        <div className="dialog-footer">
                            <PinkButton onClick={() => setIsContinue(true)}>Ok,lock</PinkButton>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="dialog-header">
                            <img
                                width={24}
                                height={24}
                                src={BackIcon}
                                alt=""
                                onClick={() => {
                                    setIsContinue(false)
                                }}
                            />
                            <h3>Lock Delegation - Confirmation</h3>
                            <img width={24} height={24} src={CloseIcon} alt="" onClick={() => handleClose()}/>
                        </div>
                        <div className="dialog-content-wrapper">
                            <div className="dialog-content">
                                <div className="dialog-content-row">
                                    <span>Validator</span>
                                    <div>{delegation.validatorName}</div>
                                </div>
                                <div className="dialog-content-row">
                                    <span>Send From</span>
                                    <div>{account}</div>
                                </div>
                                <div className="dialog-content-row">
                                    <span>Lock Delegation Until</span>
                                    <div>{`${endDate} (${days} days)`}</div>
                                </div>
                                {/* <InputField
                                    label='Please enter your wallet password to bridge the transaction'
                                    value={walletPassword}
                                    placeHolder=''
                                    type="password"
                                    appendContent='Max Fee: 0.0141784271184 SKU'
                                    // onChange={(e) => handlePasswordChange(e.target.value)}
                                /> */}
                            </div>
                            <div className="dialog-footer" style={{marginTop: '0'}}>
                                <PinkButton
                                  disabled={loading}
                                  onClick={handleAction}
                                >{btnChild}</PinkButton>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Dialog>
    )
}
