import EthIcon from '../images/ethmainnet.svg'
import BscIcon from '../images/bsc.svg'
import MetamaskIcon from '../images/metamask.svg'
import CloverIcon from '../images/clover.svg'
import SkuIcon from '../images/sku_logo.svg'
import WalletLogo from "../images/wallet_logo.svg";
import { AbstractConnector } from '@web3-react/abstract-connector'
import { InjectedConnector } from '@web3-react/injected-connector'
import { CloverConnector } from '@clover-network/clover-connector'
import {WalletConnectConnector} from '@web3-react/walletconnect-connector'

declare global {
  interface Window {
    clover: any;
    ethereum: any;
  }
}

export const IS_DEV = process.env.REACT_APP_ENV === 'dev'

export const MAINNET_CHAIN_ID = '0x1'
export const ROPSTEN_CHAIN_ID = '0x3'
export const RINKEBY_CHAIN_ID = '0x4'
export const BSC_MAINNET_CHAIN_ID = '0x38'
export const SKU_CHAIN_ID = '0xfa4'

export const WALLET_NAME_METAMASK = 'MetaMask'
export const WALLET_NAME_CLV = 'CLV Wallet'
export const WALLET_NAME_WC = 'Wallet Connect'

export const ETH_CHAIN_ID = MAINNET_CHAIN_ID
export const BSC_CHAIN_ID = BSC_MAINNET_CHAIN_ID

export const SAKURA_NETWORK_RPC_URL = 'https://test-rpc.sakurafinance.io'

export interface ChainInfo {
  type: string,
  icon: string,
  rpcUrl: string,
  chainId: string,
  nickname: string,
  ticker: string,
  tokenAddress?: string,
}

export const ETH_MAINNET_NETWORK:ChainInfo = {
    type: 'rpc',
    icon: EthIcon,
    rpcUrl: 'https://eth-04.dccn.ankr.com/',
    chainId: MAINNET_CHAIN_ID,
    nickname: 'Ethereum Mainnet',
    ticker: 'ETH',
    tokenAddress: '0xab7d26316f5c0adD393C2dDA2E6dA43D750DD399',
}

export const ETH_RINKEBY_NETWORK:ChainInfo = {
    type: 'rpc',
    icon: EthIcon,
    rpcUrl: 'https://rinkeby.infura.io/v3/ca6ba1082a5845988ab59ad70a8fac5c',
    chainId: RINKEBY_CHAIN_ID,
    nickname: 'Rinkeby',
    ticker: 'ETH',
}

export const ETH_ROPSTEN_NETWORK:ChainInfo = {
    type: 'rpc',
    icon: EthIcon,
    rpcUrl: 'https://ropsten.infura.io/v3/bd6d2612b6c8462a99385dc5c89cfd41',
    chainId: ROPSTEN_CHAIN_ID,
    nickname: 'Ropsten',
    ticker: 'ETH',
}

export const BSC_MAINNET_NETWORK:ChainInfo = {
    type: 'rpc',
    icon: BscIcon,
    rpcUrl: 'https://bscrpc.com',
    chainId: BSC_MAINNET_CHAIN_ID,
    nickname: 'BNB Smart Chain',
    ticker: 'BNB',
    tokenAddress: '0x664E9A36a18a2e1152aeD79a2F1Cc8833FD37115',
}

export const SAKURA_NETWORK:ChainInfo = {
    type: 'rpc',
    icon: SkuIcon,
    rpcUrl: SAKURA_NETWORK_RPC_URL,
    chainId: SKU_CHAIN_ID,
    nickname: 'Sakura',
    ticker: 'SKU',
}

export const OPTIONS = [
    ETH_MAINNET_NETWORK,
    BSC_MAINNET_NETWORK,
    SAKURA_NETWORK
]

export const getSupportedChains = () => {
  const supportedChainIds = OPTIONS.map(x => Number(x.chainId))
  const rpcs = OPTIONS.reduce((acc: any, x) => {
    acc[Number(x.chainId)] = x.rpcUrl
    return acc
  }, {})
  return {
    supportedChainIds,
    rpcs
  }
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  icon: any
  downloadUrl: string
}

const opt = getSupportedChains()
export const injected = new InjectedConnector({
  supportedChainIds: opt.supportedChainIds
})

export const clvConnector = new CloverConnector({ supportedChainIds: opt.supportedChainIds })

export const wcConnector = new WalletConnectConnector({
  supportedChainIds: opt.supportedChainIds,
  rpc: opt.rpcs
})

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  METAMASK: {
    connector: injected,
    name: WALLET_NAME_METAMASK,
    icon: MetamaskIcon,
    downloadUrl: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'
  },
  CLV_WALLET: {
    connector: clvConnector,
    name: WALLET_NAME_CLV,
    icon: CloverIcon,
    downloadUrl: 'https://chrome.google.com/webstore/detail/clover-wallet/nhnkbkgjikgcigadomkphalanndcapjk'
  },
  WALLET_CONNECT: {
    connector: wcConnector,
    name: WALLET_NAME_WC,
    icon: WalletLogo,
    downloadUrl: 'https://chrome.google.com/webstore/detail/clover-wallet/nhnkbkgjikgcigadomkphalanndcapjk'
  }
}

export const getProviderByWalletName = (walletName: string) => {
  let wallet
  if (walletName === WALLET_NAME_METAMASK) {
    wallet = SUPPORTED_WALLETS.METAMASK
  } else if (walletName === WALLET_NAME_CLV) {
    wallet = SUPPORTED_WALLETS.CLV_WALLET
  } else {
    throw new Error('Invalid wallet name')
  }

  return wallet
}

export const SFC_ADDRESS = "0xfc00face00000000000000000000000000000000"
export const GRAPH_URL = 'https://test-graph.sakurafinance.io/'
export const CLAIM_OPERATIONS = {
  CLAIM: 'CLAIM',
  RESTAKE: 'RESTAKE'
}

export const DAY_DURATION = 86400
export const LOCK_MAX_DAY = 365
export const LOCK_MIN_DAY = 14
export const LOCK_MAX_DURATION = LOCK_MAX_DAY * DAY_DURATION
export const LOCK_MIN_DURATION = LOCK_MIN_DAY * DAY_DURATION
export const WITHDRAW_DURATION = 7 * DAY_DURATION

export const SOCIAL_ICONS = {
  twitter: 'twitter',
  telegram: 'telegram',
  discord: 'discord',
  medium: 'medium',
};

export const SOCIALS = [
  {
    name: 'twitter',
    link: 'https://twitter.com/clover_finance/',
  },
  {
    name: 'telegram',
    link: 'https://t.me/clover_en/',
  },
  {
    name: 'discord',
    link: 'https://discord.com/invite/z2egJBsBWx/',
  },
  {
    name: 'medium',
    link: 'https://projectclover.medium.com/',
  },
];
