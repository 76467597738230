import {useWeb3React} from "@web3-react/core";
import { BSC_MAINNET_NETWORK, ETH_MAINNET_NETWORK, SAKURA_NETWORK } from "constants/base";
import React, {useEffect, useState} from 'react'
import { getPrice } from "utils/backendUtils";
import { formatWithFp, getGasLimit, getGasPrice } from "utils/balanceUtils";
import Web3 from "web3";
import { BigNumber as BN } from "bignumber.js";

const getUsdPrice = (priceObj, selectedChain) => {
  if (selectedChain.chainId === ETH_MAINNET_NETWORK.chainId) {
    return priceObj?.['ethereum'].usd ?? 0
  } else if (selectedChain.chainId === BSC_MAINNET_NETWORK.chainId) {
    return priceObj?.['binancecoin'].usd ?? 0
  } else if (selectedChain.chainId === SAKURA_NETWORK.chainId) {
    return priceObj?.['sakura'].usd ?? 0
  }
  throw new Error('Invalid chain')
}

export function useEstimateFee(selectedChain) {
  const { account } = useWeb3React()
  const [feeLoading, setFeeLoading] = useState(false)
  const [gasLimit, setGasLimit] = useState('0')
  const [gasPrice, setGasPrice] = useState('0')
  const [gasInToken, setGasInToken] = useState('0')
  const [gasInUsd, setGasInUsd] = useState('0')
  useEffect(() => {
    if (!account) {
      return
    }
    setFeeLoading(true)
    const web3 = new Web3(selectedChain.rpcUrl)
    Promise.all([
      getGasPrice(web3),
      getGasLimit(web3, selectedChain, account),
      getPrice()
    ]).then((ret) => {
      setGasPrice(ret[0])
      setGasLimit(ret[1])
      const usd = getUsdPrice(ret[2], selectedChain)
      const tokenGas = formatWithFp(new BN(ret[0]).times(new BN(ret[1])).toFixed(0))
      const usdGas = formatWithFp(new BN(ret[0]).times(new BN(ret[1])).times(new BN(usd)).toFixed(0))
      setGasInToken(tokenGas)
      setGasInUsd(usdGas)
    }).finally(() => {
      setFeeLoading(false)
    })
  }, [account, selectedChain])

  return {
    gasLimit,
    gasPrice,
    gasInToken,
    gasInUsd,
    feeLoading
  }
}