import React, {useState} from 'react'
import './index.scss'
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../../images/dialog_close.svg'
import {PinkButton} from "../../Button";
import {useWeb3React} from "@web3-react/core";
import { skuUtil } from 'utils/sakuraUtil';
import { useBtnChild } from 'hooks/useBtnChild';
import { CLAIM_OPERATIONS } from 'constants/base';

export default function ClaimRewardsDialog(props) {
    const { handleClose, open, operation, delegation, handleSuccess } = props;
    const { account, library } = useWeb3React()
    const [loading, setLoading] = useState(false)
    // const [walletPassword, setWalletPassword] = useState('')
    // const [showAdvance, setShowAdvance] = useState(false)
    // const [gasPrice, setGasPrice] = useState(0)
    // const [gasLimit, setGasLimit] = useState(0)

    const btnChild = useBtnChild(loading, operation === CLAIM_OPERATIONS.CLAIM ? 'Claim Rewards' : 'Claim & Restake Rewards')

    const getTitle = () => {
      if (operation === CLAIM_OPERATIONS.CLAIM) {
        return 'Claim Rewards'
      } else if (operation === CLAIM_OPERATIONS.RESTAKE) {
        return 'Claim & Restake Rewards'
      }
    }

    const handleAction = async () => {
      setLoading(true)
      try {
        const txResult = await skuUtil.claimOrRestakeRewards(account, delegation.validatorId, operation === CLAIM_OPERATIONS.CLAIM, library)
        handleClose()
        handleSuccess(`${getTitle()} Successful`, txResult.txHash)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    // const handlePasswordChange = (val) => {
    //     setWalletPassword(val)
    // }

    return (
        <Dialog className="claim-rewards-dialog" onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
            <div className="dialog-wrapper">
                <div>
                    <div className="dialog-header">
                        <div className="claim-rewards-title">
                            {getTitle()}
                        </div>
                        <img width={24} height={24} src={CloseIcon} alt="" onClick={() => handleClose()}/>
                    </div>
                    <div className="dialog-content-wrapper">
                        <div className="dialog-content">
                            <div className="dialog-content-row">
                                <span>Validator</span>
                                <div>{delegation.validatorName}</div>
                            </div>
                            <div className="dialog-content-row">
                                <span>Amount</span>
                                <div>{delegation.pendingRewardsForDisplay} SKU</div>
                            </div>
                            {/* <InputField
                                label='Please enter your wallet password to bridge the transaction'
                                value={walletPassword}
                                placeHolder=''
                                type="password"
                                appendContent='Max Fee: 0.0141784271184 SKU'
                                onChange={(e) => handlePasswordChange(e.target.value)}
                            /> */}
                            {/* <div className="advanced-wrapper">
                                <h3>
                                    Advanced Functions
                                    <img onClick={() => setShowAdvance(true)} src={ArrowDown} alt=""/>
                                </h3>
                                {showAdvance && (
                                    <div>
                                        <GasItem
                                            text='Gas Price (GWEI)'
                                            value={gasPrice}
                                            onChange={(val) => setGasPrice(val)}
                                        />
                                        <GasItem
                                            text='Gas Limit'
                                            value={gasLimit}
                                            onChange={(val) => setGasLimit(val)}
                                        />
                                    </div>
                                )}
                            </div> */}
                        </div>
                        <div className="dialog-footer">
                            <PinkButton
                              disabled={loading}
                              onClick={handleAction}
                            >{btnChild}</PinkButton>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
