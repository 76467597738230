import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CopyHead = (props: SvgIconProps) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="15.5" stroke="white" stroke-opacity="0.12"/>
            <path d="M17.7188 24H11.75C10.3715 24 9.25 22.8785 9.25 21.5V13.0312C9.25 11.6527 10.3715 10.5312 11.75 10.5312H17.7188C19.0973 10.5312 20.2188 11.6527 20.2188 13.0312V21.5C20.2188 22.8785 19.0973 24 17.7188 24ZM11.75 11.7812C11.0608 11.7812 10.5 12.342 10.5 13.0312V21.5C10.5 22.1892 11.0608 22.75 11.75 22.75H17.7188C18.408 22.75 18.9688 22.1892 18.9688 21.5V13.0312C18.9688 12.342 18.408 11.7812 17.7188 11.7812H11.75ZM22.7188 19.9375V10.5C22.7188 9.12146 21.5973 8 20.2188 8H13.2812C12.936 8 12.6562 8.27979 12.6562 8.625C12.6562 8.97021 12.936 9.25 13.2812 9.25H20.2188C20.908 9.25 21.4688 9.81079 21.4688 10.5V19.9375C21.4688 20.2827 21.7485 20.5625 22.0938 20.5625C22.439 20.5625 22.7188 20.2827 22.7188 19.9375Z" fill="currentColor"/>
        </svg>
    );
};
