import React, {useState} from 'react'
import './index.scss'
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../../images/dialog_close.svg'
import {PinkButton} from "../../Button";
import Remind from "../../Remind";

export default function RemoveWalletDialog(props) {
    const { handleClose, open } = props;
    const address = '0xae7eba2ef5bb8b2420728c65c081d30d84559eb1'

    return (
        <Dialog className="remove-wallet-dialog" onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
            <div className="dialog-wrapper">
                <div>
                    <div className="dialog-header">
                        <h3>Remove Wallet</h3>
                        <img width={24} height={24} src={CloseIcon} alt="" onClick={() => handleClose()}/>
                    </div>
                    <div className="dialog-content">
                        <div className="remove-wallet-head" >Are you sure you want to remove wallet {address} ?</div>
                        <Remind content='Removing a wallet clears it from local storage. You will not be able to access it again unless you restore via mnemonic phrase, keystore file or private key. This action is irreversible.' />
                    </div>
                    <div className="dialog-footer">
                        <PinkButton>Remove</PinkButton>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
