import React, { useState, useEffect } from 'react';
import { useConnectWalletStyles } from './useConnectWalletStyles';
import {t} from "../../modules/i18n/intl";
import ConnectBtn from '../../images/connect_btn.svg'
import CreateBtn from '../../images/create_btn.svg'
import RetoreBtn from '../../images/restore_btn.svg'
import CopyIcon from '../../images/copy_icon.svg'
import EditIcon from '../../images/edit_icon.svg'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import {shortenAddress} from "../../utils/helper";
import CreateWalletDialog from "../../components/Dialog/CreateWalletDialog";
import RestoreWalletDialog from "../../components/Dialog/RestoreWalletDialog";
import {getLocalWallets, setCurrentWallet, setLocalWallets} from "../../utils/LocalstorageUtils";
import _ from "lodash";
import {SAKURA_NETWORK, SUPPORTED_WALLETS, WALLET_NAME_CLV, WALLET_NAME_METAMASK, WALLET_NAME_WC} from "constants/base";
import { changeWalletNetwork } from 'connectors';
import { toast } from 'react-hot-toast';

export const TopContent = (props) => {
  const classes = useConnectWalletStyles();
  const { leftContent, rightContent } = props
  return (
      <div className={classes.homeTop}>
        <div className={classes.homeTopLeft}>
            {leftContent.map((item, index) => (
                <div className={classes.homeTopLeftItem} key={`${item.title}_${index}`}>
                    <span>{item.title}</span>
                    <div>{item.price} <span>{item.amount}</span></div>
                </div>
            ))}
        </div>
        <div className={classes.homeTopRight}>
            {rightContent.map((item, index) => (
                <div
                    className={classes.homeTopRightItem}
                    key={`${item.text}_${index}`}
                    onClick={() => item.onClick ? item.onClick() : {}}
                >
                    <img width={48} src={item.icon} alt=""/>
                    <span>{item.text}</span>
                </div>
            ))}
        </div>
      </div>
  )
}

export const WalletItem = (props) => {
  const classes = useConnectWalletStyles();
  const { wallet, handleOpenEditWallet } = props
  return (
      <div className={classes.homeWalletItem}>
        <div className={classes.homeWalletItemLeft}>
          <h3>{wallet.name}</h3>
          <span>{shortenAddress(wallet.address)}</span>
          <span>{wallet.walletType}</span>
        </div>
        <div className={classes.homeWalletItemCenter}>
          <div>
            <h3>{wallet.balance} <span>SKU</span></h3>
            <span>$0.82</span>
            <span>Available</span>
          </div>
          <div>
            <h3>{wallet.balance} <span>SKU</span></h3>
            <span>$0.82</span>
            <span>Total</span>
          </div>
        </div>
        <div className={classes.homeWalletItemRight}>
          <img src={CopyIcon} alt=""/>
          <img onClick={() => handleOpenEditWallet()} src={EditIcon} alt=""/>
        </div>
      </div>
  )
}

export default function ConnectWalletPage(props) {
  const { onOpenEditWallet, onOpenConnectWallet } = props
  const classes = useConnectWalletStyles();
  const [selectedWallet, setSelectedWallet] = useState({});
  const { account, active, activate, library } = useWeb3React()
  const [wallets, setWallets] = useState(getLocalWallets())
  const [openCreateWallet, setOpenCreateWallet] = useState(false)
  const [openRestoreWallet, setOpenRestoreWallet] = useState(false)
  const [selectWalletTimes, setSelectWalletTimes] = useState(0)

  useEffect(() => {
    if (selectedWallet.connector) {
      if (selectedWallet.name === WALLET_NAME_METAMASK && (!window.ethereum || !window.ethereum.isMetaMask)) {
        window.open(selectedWallet.downloadUrl)
        return
      }

      if (selectedWallet.name === WALLET_NAME_CLV && !window.clover) {
        window.open(selectedWallet.downloadUrl)
        return
      }
      connectWallet()
    }
  }, [selectedWallet, selectWalletTimes])

  useEffect(() => {
    if (selectedWallet.connector && active) {
      const obj = {
        name: selectedWallet.name,
        walletType: 'WalletConnect',
        address: account,
        balance: 0
      }
      setLocalWallets(obj)
      setCurrentWallet(obj)
    }
  }, [active, library])

  const connectWallet = async () => {
    if (selectedWallet.connector) {
      await activate(selectedWallet.connector, async (error) => {
        // error handle
        if (error instanceof UnsupportedChainIdError) {
          if (selectedWallet.name === WALLET_NAME_WC) {
            toast('Please switch to sakura chain')
          } else {
            selectedWallet?.connector?.getProvider().then((provider) => {
              if (provider) {
                changeWalletNetwork(provider, SAKURA_NETWORK, provider.chainId)
                .then(() => activate(selectedWallet.connector, (error) => {toast('Please switch to sakura chain')}))
                .catch(e => {toast('Please switch to sakura chain')})
              }
            })
          }
        }
      })
    }
  }

  useEffect(() => {
    const wallet = getLocalWallets()
    setWallets(getLocalWallets())
    let refreshInterval;
    refreshInterval = setInterval(() => {
      setWallets(getLocalWallets())
    }, 3000)
    return () => clearInterval(refreshInterval);
  }, [account, active]);

  const leftContent = [
    {
      title: 'Available',
      price: '$0.82',
      amount: '0.75 SKU'
    },
    {
      title: 'Total',
      price: '$0.82',
      amount: '0.75 SKU'
    }
  ]
  const rightContent = [
    {
      icon: ConnectBtn,
      text: 'Connect Wallet',
      onClick: onOpenConnectWallet
    }, {
      icon: CreateBtn,
      text: 'Create Wallet',
      onClick: () => setOpenCreateWallet(true)
    }, {
      icon: RetoreBtn,
      text: 'Restore Wallet',
      onClick: () => setOpenRestoreWallet(true)
    }
  ]

  return (
    <div className={classes.root}>
      <div className={classes.welcome}>
        <div className={classes.welcomeHead}>
          <h3>{t('connectWallet')}</h3>
          <span>{t('connectWalletHint')}</span>
        </div>
        <div className={classes.welcomeContent}>
          {
            _.map(SUPPORTED_WALLETS, (item, index) => (
                <div
                    className={classes.walletItem}
                    key={`${item.name}_${index}`}
                    onClick={() => {
                      setSelectedWallet(item)
                      setSelectWalletTimes(selectWalletTimes + 1)
                    }}
                >
                  <img width={70} src={item.icon} alt=""/>
                  <span>{item.name}</span>
                </div>
            ))
          }
          {/* <div
              className={classes.walletItem}
              onClick={() => {
                onConnectWallSanCode()
              }}
          >
            <img width={70} src={WalletLogo} alt=""/>
            <span>WalletConnect</span>
          </div> */}
        </div>
      </div>
      {openCreateWallet && <CreateWalletDialog
          open={openCreateWallet}
          handleClose={() => setOpenCreateWallet(false)}
      />}
      {openRestoreWallet && <RestoreWalletDialog
          open={openRestoreWallet}
          handleClose={() => setOpenRestoreWallet(false)}
      />}
    </div>
  );
}
