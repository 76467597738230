import {useWeb3React} from "@web3-react/core";
import { useReceiveStyles } from './useReceiveStyles';
import Header from "../../components/Header/Header";
import QRCode from "qrcode-react";
import {CopyAddress} from "../../components/CopyAddress/CopyAddress";

export default function ReceiveComponent(props) {
    const { onOpenConnectWallet, onOpenAddressCode } = props
    const classes = useReceiveStyles();
    const { account } = useWeb3React()
  return (
    <div className={classes.root}>
      <Header title="Receive" onOpenConnectWallet={onOpenConnectWallet} onOpenAddressCode={onOpenAddressCode} />
      <div className={classes.content}>
          <div className={classes.codeWrapper}>
              <QRCode value={account} size={180} />
          </div>
          <CopyAddress address={account} />
      </div>
    </div>
  );
}
