import React, {useEffect, useState} from 'react';
import { makeStyles, Theme } from '@material-ui/core';
// import CopyHead from "../../images/copy_head.svg";
// import QrCodeHead from "../../images/qr_code_head.svg";
import ArrowDown from '../../images/arrow_down.svg'
import AccountIcon from "../../images/account.svg";
import SwitchIcon from "../../images/switch_icon.svg";
import LogoutIcon from "../../images/logout_icon.svg";
import { CopyHead } from 'modules/icons/CopyHead';
import { QrCodeHead } from 'modules/icons/QrCodeHead';

import {useWeb3React} from "@web3-react/core";
import {shortenAddress} from "../../utils/helper";
import {getCurrentWallet, setCurrentWallet, setLocalWallets} from "../../utils/LocalstorageUtils";
import {ToastTooltip} from "../ToastTooltip";
import {CorrectIcon} from "../../modules/icons/CorrectIcon";
import {useHistory} from 'react-router-dom';

const useHeaderStyle = makeStyles<Theme>(theme => ({
  head: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    boxShadow: "0px 1px 0px rgba(255, 255, 255, 0.12)",
    paddingBottom: '17px',
    fontFamily: 'NeverMind-DemiBold',
  },
  headLeft: {
    color: '#FFFFFF',
    fontSize: '32px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&>img': {
      marginLeft: '10px'
    }
  },
  headRight: {
    position: 'relative',
  },
  headRightContent: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '5px',
    '&>div': {
      margin: '0 12px',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
      '&>span': {
        fontSize: '14px',
        color: '#888686'
      }
    },
    '&:hover': {
      background: '#1F1F1F',
      borderRadius: '12px'
    }
  },
  headRightMenu: {
    position: 'absolute',
    zIndex: 1,
    top: '60px',
    right: '0',
    background: '#2A2A2A',
    boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.2), 0px 52px 37px -20px rgba(0, 0, 0, 0.25)',
    backdropFilter: 'blur(100px)',
    borderRadius: '20px',
    padding: '16px',
  },
  menuItemWallet: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    padding: '16px 0',
    '&>div': {
      display: 'flex',
      alignItems: 'center',
      '&>div': {
        margin: '0 12px',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        '&>span': {
          fontSize: '14px',
          color: '#888686'
        }
      }
    },
  },
  icons: {
    marginLeft: '50px',
    display: 'flex',
    alignItems: 'center',
    '&>div': {
      margin: '0!important',
      cursor: 'pointer',
      height: '32px'
    },
    '& svg': {
      marginLeft: '10px',
      cursor: 'pointer',
      color: '#888686',
      '&:hover': {
        color: '#fff'
      }
    }
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 0',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    cursor: 'pointer',
    '&:hover': {
      background: '#1F1F1F',
      borderRadius: '12px',
    },
    '&>img': {
      marginRight: '16px'
    }
  }
}));

const Header = (props: any) => {
  const { account, deactivate, library } = useWeb3React()
  const history = useHistory()
  const [curWallet, setCurWallet] = useState(getCurrentWallet())
  const [showWalletMenu, setShowWalletMenu] = useState(false)
  const { title, onOpenAddressCode, onOpenConnectWallet } = props
  const classes = useHeaderStyle();
  const handleLogout = async () => {
    await deactivate()
    setLocalWallets([])
    setCurrentWallet({})
    setCurWallet({})
    history.push('/connectWallet')
  }

  useEffect(() => {
    setCurrentWallet(getCurrentWallet())
  }, [account, library])

  useEffect(() => {
    let interval = setInterval(() => {
      setCurWallet(getCurrentWallet())
    }, 1000)
    return () => clearInterval(interval)
  }, []);

  useEffect(() => {
    if (!account) {
      history.push('/connectWallet')
    }
  }, [account, history])

  const [copyTooltipOpen, setCopyTooltipOpen] = useState(false);
  const onCopyClicked = () => {
    navigator.clipboard.writeText(account??'');
    if (copyTooltipOpen) {
      return;
    }
    setCopyTooltipOpen(true);
    setTimeout(() => {
      setCopyTooltipOpen(false);
    }, 3 * 1000);
  };

  window.addEventListener('click', (e) => {
    setShowWalletMenu(false)
  })

  return (
      <div className={classes.head} onClick={(e) => {
        e.stopPropagation()
        setShowWalletMenu(false)
      }}>
        <h3 className={classes.headLeft}>
          {title}
        </h3>
        <div className={classes.headRight}>
          <div className={classes.headRightContent} onClick={(e) => {
            e.stopPropagation()
            setShowWalletMenu(!showWalletMenu)
          }}>
            <img width={24} src={AccountIcon} alt=""/>
            <div>
              <div>{account ? shortenAddress(account) : ''}</div>
              <span>{curWallet.name}</span>
            </div>
            <img width={16} src={ArrowDown} alt=""/>
          </div>
          {showWalletMenu && (
              <div onClick={(e) => e.stopPropagation()} className={classes.headRightMenu}>
                <div className={classes.menuItemWallet}>
                  <div>
                    <img width={24} src={AccountIcon} alt=""/>
                    <div>
                      <div>{account ? shortenAddress(account) : ''}</div>
                      <span>{curWallet.name}</span>
                    </div>
                  </div>
                  <div className={classes.icons}>
                    <ToastTooltip
                        style={{ width: '100%' }}
                        open={copyTooltipOpen}
                        message="Copied!"
                        icon={<CorrectIcon />}
                        placement="top"
                    >
                      <div onClick={onCopyClicked}>
                        <CopyHead />
                      </div>
                    </ToastTooltip>
                    <div onClick={() => onOpenAddressCode()}>
                      <QrCodeHead />
                    </div>
                  </div>
                </div>
                <div
                    className={classes.menuItem}
                    onClick={() => onOpenConnectWallet()}
                >
                  <img width={24} src={SwitchIcon} alt=""/>
                  <div>Switch Wallet Provider</div>
                </div>
                <div
                    className={classes.menuItem}
                    onClick={() => handleLogout()}
                >
                  <img width={24} src={LogoutIcon} alt=""/>
                  <div>Logout</div>
                </div>
              </div>
          )}
        </div>
      </div>
  );
};

export default Header;
