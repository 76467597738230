import { alpha, createTheme, ThemeOptions } from '@material-ui/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';

const FONTS = {
  semiBold: ['"Inter-SemiBold"', 'sans-serif'].join(','),
  medium: ['"Inter-Medium"', 'sans-serif'].join(','),
};

export const PALETTE = {
  common: {
    black: '#000000',
    white: '#ffffff',
  },

  primary: {
    main: '#ffffff',
    light:'#888686',
  },
  text: {
    primary: '#ffffff',
    secondary: '#888686',
  },

  background: {
    default: '#020405',
    paper: '#1C2833',
  },

  warning: {
    main: '#FFE1E1',
  },

  grey: {
    100: '#e5e5e5',
    200: '#e8e9ea',
    300: '#7f7f7f',
    400: '#292929',
    500: '#1c2733',
    A100: alpha('#fff', 0.06),
    A200: alpha('#fff', 0.3),
    A400: alpha('#fff', 0.4),
    A700: alpha('#fff', 0.5),
  },

  action: {
    hover: alpha('#fff', 0.16),
  },

  success: {
    main: '#11EAAE',
  },

  info: {
    main: '#A0B5FF',
  },

  secondary: {
    main: '#ff9e68',
  },

  error: {
    main: '#FA5050',
  },
};

export const defaultTheme = createTheme({
  spacing: 4,
  palette: PALETTE as PaletteOptions,
});

export const mainTheme = createTheme({
  spacing: defaultTheme.spacing,
  palette: defaultTheme.palette,

  typography: {
    fontFamily: FONTS.semiBold,
    color: PALETTE.text.primary,

    h1: { fontSize: 42, fontWeight: 600, lineHeight: 1.2, fontFamily: 'NeverMind-DemiBold' },
    h2: { fontSize: 26, fontWeight: 600, lineHeight: 1.2, fontFamily: 'NeverMind-DemiBold' },
    h3: { fontSize: 22, fontWeight: 600, lineHeight: 1.2, fontFamily: 'NeverMind-DemiBold' },
    h4: { fontSize: 20, fontWeight: 600, lineHeight: 1.2, fontFamily: 'NeverMind-DemiBold' },
    h5: { fontSize: 16, fontWeight: 600, lineHeight: 1.2 },
    h6: { fontSize: 14, fontWeight: 600, lineHeight: 1.2 },

    subtitle1: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1,
      marginBottom: 10,
      color: defaultTheme.palette.text.primary,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 600,
      marginBottom: 10,
      color: defaultTheme.palette.text.secondary,
    },
    caption: { fontSize: 22, fontWeight: 600, lineHeight: 1 },

    body1: { fontSize: 14, fontWeight: 600, lineHeight: 1.3 },
    body2: { fontFamily: FONTS.medium, fontWeight: 500, fontSize: 12 },
  },

  overrides: {
    MuiButton: {
      root: {
        fontSize: 14,
        color: defaultTheme.palette.primary.main,
        height: 36,
        minWidth: 0,
        padding: defaultTheme.spacing(0, 4),
        textTransform: 'none',
        borderRadius: 8,
        '&:disabled': {
          cursor: 'not-allowed',
        },
        '& svg': {
          width: 16,
          height: 16,
        },
      },
      startIcon: {
        marginRight: 4,
        marginLeft: 4,
        '&.MuiButton-iconSizeSmall': {
          marginRight: 2,
          marginLeft: 2,
        },
      },
      contained: {
        color: defaultTheme.palette.common.black,
        backgroundColor: defaultTheme.palette.primary.main,
        '&:hover': {
          color: defaultTheme.palette.common.black,
          backgroundColor: '#24A95F',
        },
        '&:disabled': {
          color: defaultTheme.palette.common.black,
          backgroundColor: alpha('#06E594', 0.5),
        },
      },
      outlined: {
        color: defaultTheme.palette.common.white,
        border: `1px solid rgba(255, 255, 255, 0.5)`,
        '&:hover': {
          backgroundColor: 'rgba(245, 247, 246, 0.2)',
          boxShadow: '0px 2px 13px rgba(5, 60, 75, 0.05)',
        },
        '&:disabled': {
          color: alpha(defaultTheme.palette.common.white, 0.2),
          border: `1px solid ${alpha(defaultTheme.palette.text.primary, 0.6)}`,
        },
      },
      text: {
        backgroundColor: 'transparent',
        '&:hover': {
          color: 'inherit',
        },
        '&:disabled': {
          color: alpha('#fff', 0.5),
        },
      },
      sizeSmall: {
        fontSize: 12,
        height: 28,
        color: defaultTheme.palette.text.secondary,
        backgroundColor: defaultTheme.palette.common.white,
        '& svg': {
          width: 12,
          height: 12,
        },
      },
      sizeLarge: {
        height: 48,
        '& svg': {
          width: 24,
          height: 24,
        },
      },
    },

    MuiIconButton: {
      root: {
        padding: '0 6px',
      },
      sizeSmall: {
        '& svg': {
          width: 16,
          height: 16,
        },
      },
    },

    MuiDialog: {
      paper: {
        backgroundColor: defaultTheme.palette.common.black,
        width: 486,
      },
      paperWidthXs: {
        width: 360,
      },
      paperWidthSm: {
        width: 420,
      },
      paperWidthLg: {
        width: 588,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '30px 0 20px 0',
        color: defaultTheme.palette.text.primary,
        '& svg': {
          color: defaultTheme.palette.text.secondary,
          width: 16,
          height: 16,
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: 0,
      },
    },
    MuiDialogActions: {
      root: {
        padding: 0,
      },
    },

    MuiSwitch: {
      root: {
        width: 52,
        height: 28,
        padding: 0,
        margin: defaultTheme.spacing(1),
      },
      thumb: {
        width: 22,
        height: 22,
      },
      switchBase: {
        padding: 3,
        '&&$checked': {
          transform: 'translateX(24px)',
          color: defaultTheme.palette.common.white,
          '& + $track': {
            backgroundColor: defaultTheme.palette.primary.main,
            opacity: 1,
            border: 'none',
          },
        },
        '&$focusVisible $thumb': {
          color: defaultTheme.palette.primary.main,
          border: '6px solid #EFF0F1',
        },
      },
      track: {
        borderRadius: 26 / 2,
        border: `1px solid ${defaultTheme.palette.grey[400]}`,
        backgroundColor: alpha('#EFF0F1', 0.2),
        opacity: 1,
        transition: defaultTheme.transitions.create([
          'background-color',
          'border',
        ]),
      },
    },

    MuiInputLabel: {
      root: {
        fontSize: 16,
        lineHeight: '18px',
        marginBottom: defaultTheme.spacing(2),
        color: defaultTheme.palette.common.white,
      },
    },

    MuiFormHelperText: {
      root: {
        '&.Mui-error': {
          borderColor: defaultTheme.palette.error.main,
          marginBottom: 28,
          marginLeft: 0,
          fontSize: 14,
          lineHeight: 1,
          textTransform: 'capitalize',
        },
      },
    },

    MuiSelect: {
      backgroundColor: defaultTheme.palette.grey.A100,
      border: `1px solid ${defaultTheme.palette.grey[300]}`,
    },
    MuiOutlinedInput: {
      root: {
        minHeight: 50,
        backgroundColor: defaultTheme.palette.grey.A100,
        borderRadius: 8,
        marginBottom: 8,
        border: `1px solid transparent`,
        '& .MuiSelect-icon': {
          color: defaultTheme.palette.text.secondary,
        },
        '&.Mui-disabled': {
          color: 'rgba(255, 255, 255, 0.2)',
          pointerEvents: 'none',
          backgroundColor: 'transparent',
        },
        '&.Mui-error, &.Mui-error:hover, &.Mui-error.Mui-focused': {
          border: `1px solid ${defaultTheme.palette.error.main}`,
          boxShadow: `0px 0px 6px ${defaultTheme.palette.error.main}`,
        },
        '& fieldset': {
          border: 'none',
        },
        '&:hover, &.Mui-focused': {
          border: `1px solid ${defaultTheme.palette.primary.light}`,
        },
      },
      input: {
        color: defaultTheme.palette.common.white,
        fontSize: 12,
        lineHeight: 1,
        padding: '16px 10px',
      },
    },

    MuiTabs: {
      root: {
        width: '100%',
        padding: 0,
        '& button:first-of-type': {
          borderTopLeftRadius: 8,
        },
        '& button:last-of-type': {
          borderTopRightRadius: 8,
        },
      },
      indicator: {
        display: 'none',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontSize: 16,
        lineHeight: '40px',
        padding: 0,
        width: '100%',
        height: 78,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: alpha('#fff', 0.4),
        backgroundColor: '#171717',
        '&.Mui-selected': {
          color: defaultTheme.palette.primary.main,
          backgroundColor: '#2a2a2a',
        },
      },
    },
    MuiLinearProgress: {
      root: {
        minWidth: 50,
        width: '100%',
        height: 4,
        borderRadius: 4,
        marginTop: 10,
      },
      colorPrimary: {
        backgroundColor: defaultTheme.palette.text.secondary,
      },
      bar: {
        borderRadius: 5,
        backgroundColor: defaultTheme.palette.primary.main,
      },
    },
  },
} as ThemeOptions);
