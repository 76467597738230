import { ReactNode, useMemo, useState, useCallback } from 'react';
import {
  InputAdornment,
  InputLabel,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import { EyeCloseIcon } from 'modules/icons/EyeCloseIcon';
import { EyeIcon } from 'modules/icons/EyeIcon';

const useStyles = makeStyles<Theme>(theme => ({
  inputRoot: {
    marginBottom: '32px'
  },
  root: {
    '& .MuiOutlinedInput-root': {
      // border: 'none',
      // marginBottom: '8px'
    },
    '& svg': {
      color: '#888686',
      cursor: 'pointer'
    }
  },
  label: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    fontSize: '16px',
    fontWeight: 600,
    marginBottom: '12px'
  },
  append: {
   color: '#888686'
  },
  inputStart: {
    minWidth: 24,
    height: 24,
    '& img, & svg': {
      width: '100%',
      height: '100%',
    },
  },
  inputEnd: {
    minWidth: 24,
    height: 24,
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    '& img, & svg': {
      width: '100%',
      height: '100%',
    },
  },
}));

interface IInputFieldProps {
  type?: string;
  label?: ReactNode;
  disabled?: boolean;
  errorInfo?: string;
  placeHolder?: string;
  rightContent?: ReactNode;
  leftContent?: ReactNode;
  value: string | number;
  onChange?: (event: any) => void;
  appendContent?: ReactNode;
}

export const InputField = ({
  label,
  type = 'text',
  disabled,
  errorInfo,
  placeHolder,
  leftContent,
  rightContent,
  value,
  onChange,
  appendContent,
}: IInputFieldProps) => {
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  const isPasword = useMemo(() => (type === 'password' ? true : false), [type]);

  const inputProps = useMemo(() => {
    return {
      startAdornment: leftContent && (
        <InputAdornment position="start">
          <div className={classes.inputStart}>{leftContent}</div>
        </InputAdornment>
      ),
      endAdornment: rightContent && (
        <InputAdornment position="end">
          <div className={classes.inputEnd}>{rightContent}</div>
        </InputAdornment>
      ),
    };
  }, [classes, leftContent, rightContent]);

  const handleClickPassword = useCallback(
    () => setShowPassword(!showPassword),
    [showPassword],
  );

  const passwordProps = useMemo(() => {
    return {
      endAdornment: (
        <InputAdornment position="end" onClick={handleClickPassword}>
          {showPassword ? <EyeIcon /> : <EyeCloseIcon />}
        </InputAdornment>
      ),
    };
  }, [showPassword, handleClickPassword]);

  return (
    <div className={classes.inputRoot}>
      {label && <InputLabel className={classes.label}>{label}</InputLabel>}
      <TextField
        variant="outlined"
        type={isPasword ? (showPassword ? 'password' : 'text') : type}
        fullWidth
        classes={{
          root: classes.root,
        }}
        InputProps={isPasword ? passwordProps : inputProps}
        placeholder={placeHolder}
        error={!!errorInfo}
        disabled={!!disabled}
        helperText={errorInfo}
        value={value}
        onChange={onChange && onChange}
      />
      {appendContent && <div className={classes.append}>{appendContent}</div>}
    </div>
  );
};
