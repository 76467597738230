import { Select, MenuItem, ListItemIcon } from '@material-ui/core';
import styled from 'styled-components';
import _ from "lodash";
import { OPTIONS } from "constants/base";

const SelectNetworkWrapper = styled.div`
    .MuiOutlinedInput-root {
        overflow: hidden;
        min-height: unset;
        border-radius: 20px;
        margin: 0;
        border: none;
        background: none;
        transition: background 0.3s;

        &:hover {
            background: rgba(255, 255, 255, 0.1);
        }
        & > div {
            display: flex;
            align-items: flex-end;
            img {
                width: 42px;
                height: 42px;
            }
        }
        svg {
            top: unset;
            bottom: 15px;
        }
        .MuiListItemIcon-root {
            min-width: unset!important;
            margin-right: 10px;
        }
    }
   
    div {
        color: #ffffff;
    }
`

const ListItemName = styled.div`
    padding-bottom: 4px;
`

export const SelectNetwork = (props: any) => {
    const { selectedNet, changeNet } = props
    return (
        <SelectNetworkWrapper>
            <Select
                variant="outlined"
                value={selectedNet.chainId}
                onChange={(e: any) => {
                    const chain = _.find(OPTIONS, item => item.chainId === e.target.value)
                    changeNet(chain)
                }}
            >
                {OPTIONS.map((item: any, index: number) => (
                    <MenuItem
                        key={`${item.chainId}-${index}`}
                        value={item.chainId}
                    >
                        {item.icon && (
                            <ListItemIcon>
                                <img width={20} height={20} src={item.icon} alt=""/>
                            </ListItemIcon>
                        )}
                        <ListItemName>{item.nickname}</ListItemName>
                    </MenuItem>
                ))}
            </Select>
        </SelectNetworkWrapper>
    )
}
