import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const HomeIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 33 32">
            <g clip-path="url(#clip0_432_3910)">
                <g clip-path="url(#clip1_432_3910)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3187 1.61412C16.8372 1.23963 16.163 1.23963 15.6815 1.61412L3.68149 10.9474C3.35671 11.2001 3.16675 11.5885 3.16675 11.9999V26.6666C3.16675 27.7275 3.58818 28.7449 4.33832 29.495C5.08847 30.2452 6.10588 30.6666 7.16675 30.6666H12.5001H20.5001H25.8334C26.8943 30.6666 27.9117 30.2452 28.6618 29.495C29.412 28.7449 29.8334 27.7275 29.8334 26.6666V11.9999C29.8334 11.5885 29.6435 11.2001 29.3187 10.9474L17.3187 1.61412ZM21.8334 27.9999H25.8334C26.187 27.9999 26.5262 27.8594 26.7762 27.6094C27.0263 27.3593 27.1667 27.0202 27.1667 26.6666V12.652L16.5001 4.35573L5.83341 12.652V26.6666C5.83341 27.0202 5.97389 27.3593 6.22394 27.6094C6.47399 27.8594 6.81313 27.9999 7.16675 27.9999H11.1667V15.9999C11.1667 15.2635 11.7637 14.6666 12.5001 14.6666H20.5001C21.2365 14.6666 21.8334 15.2635 21.8334 15.9999V27.9999ZM13.8334 27.9999V17.3333H19.1667V27.9999H13.8334Z"/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_432_3910">
                    <rect width="32" height="32" fill="currentColor" transform="translate(0.5)"/>
                </clipPath>
                <clipPath id="clip1_432_3910">
                    <rect width="32" height="32" fill="currentColor" transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </SvgIcon>
    );
};
