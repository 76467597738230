import Web3 from "web3";
import { BigNumber as BN } from "bignumber.js";
import { delay } from "./timeUtils";
import { SAKURA_NETWORK, SFC_ADDRESS, SKU_CHAIN_ID } from "constants/base";
import { changeWalletNetwork } from "connectors";

const abi = require("../constants/SFC.json")

// const PRIVATE_KEY = 'fcca336ee4cd4b697aad5f42f6a8a8572599b6d71d3cb14e49e6cd3cd3d7ba2f'
// const account = web3.eth.accounts.privateKeyToAccount(PRIVATE_KEY)

class SakuraUtil {
  web3: any
  contract: any

  async setWeb3AndContract (library: any) {
    if (!library) {
      throw new Error('No wallet connected!')
    }
    if (parseInt(library.chainId) !== parseInt(SKU_CHAIN_ID)) {
      await changeWalletNetwork(library, SAKURA_NETWORK, library.chainId)
    }
    this.web3 = new Web3(library)
    this.contract = new this.web3.eth.Contract(abi, SFC_ADDRESS)
  }

  async delegate(accountAddress: string, amount: string, toValidatorID: number, library: any) {
    await this.setWeb3AndContract(library)
    const amountBig = this.web3.utils.toWei(amount, "ether")
    const receipt = await this.contract.methods.delegate(toValidatorID).send({
      from: accountAddress,
      to: SFC_ADDRESS /* SFC Contract */,
      value:  amountBig,
    })
    
    const txStatus = await this.checkTxStatus(receipt.transactionHash) 
    return {
      txStatus,
      txHash: receipt.transactionHash
    }
  }

  async claimOrRestakeRewards(account: string, toValidatorID: number, claim: boolean, library: any) {
    await this.setWeb3AndContract(library)
    const method = claim ? 'claimRewards' : 'restakeRewards'

    const receipt = await this.contract.methods[method](toValidatorID).send({
      from: account,
      to: SFC_ADDRESS
    })

    const txStatus = await this.checkTxStatus(receipt.transactionHash) 
    return {
      txStatus,
      txHash: receipt.transactionHash
    }
  }

  // lockupDuration unit is second
  async lock(account: string, amount: string, toValidatorID: number, lockupDuration: number, library: any) {
    await this.setWeb3AndContract(library)

    //todo, for test
    const duration = 1200
    const gasEstimate = await this.contract.methods.lockStake(toValidatorID, duration, amount).estimateGas({from: account})
    // todo end

    const receipt = await this.contract.methods.lockStake(toValidatorID, duration, amount).send({
      from: account,
      to: SFC_ADDRESS
    })

    const txStatus = await this.checkTxStatus(receipt.transactionHash) 
    return {
      txStatus,
      txHash: receipt.transactionHash
    }
  }

  async unlock(account: string, amount: string, toValidatorID: number, library: any) {
    await this.setWeb3AndContract(library)
    const amountBig = new BN(amount).toFixed()
    const receipt = await this.contract.methods.unlockStake(toValidatorID, amountBig).send({
      from: account,
      to: SFC_ADDRESS
    })

    const txStatus = await this.checkTxStatus(receipt.transactionHash) 
    return {
      txStatus,
      txHash: receipt.transactionHash
    }
  }

  async undelegate(account: string, amount: string, toValidatorID: number, wrID: number, library: any) {
    await this.setWeb3AndContract(library)
    const receipt = await this.contract.methods.undelegate(toValidatorID, wrID, amount).send({
      from: account,
      to: SFC_ADDRESS
    })

    const txStatus = await this.checkTxStatus(receipt.transactionHash) 
    return {
      txStatus,
      txHash: receipt.transactionHash
    }
  }

  async withdraw(account: string, toValidatorID: number, wrID: number, library: any) {
    await this.setWeb3AndContract(library)

    const receipt = await this.contract.methods.withdraw(toValidatorID, wrID).send({
      from: account,
      to: SFC_ADDRESS
    })

    const txStatus = await this.checkTxStatus(receipt.transactionHash) 
    return {
      txStatus,
      txHash: receipt.transactionHash
    }
  }

  async checkTxStatus(txHash: string) {
    if (!this.web3) {
      throw new Error('Invalid web3 for checking tx status!')
    }

    try {
      while(true) {
        const receipt = await this.web3.eth.getTransactionReceipt(txHash)
        if (receipt?.status) {
          return true
        }
        await delay(3000)
      }
    } catch(e) {
      return false
    }
  }
}

export const skuUtil = new SakuraUtil()

// export async function undelegate(account: string, amount: string, toValidatorID: number, wrID: number) {
//     const method = contract.methods.undelegate(toValidatorID, wrID, web3.utils.toWei(amount, "ether"))
//     const txHash = await sendContractTx(method, account, SFC_ADDRESS)
//     console.log(txHash)
// }

// // after undelegated for 3 epochs (withdrawalPeriodEpochs)
// export async function withdraw(account: string, toValidatorID: number, wrID: number) {
//     const method = contract.methods.withdraw(toValidatorID, wrID)
//     const txHash = await sendContractTx(method, account, SFC_ADDRESS)
//     console.log(txHash)
// }

// // 86400 * 14 (2 weeks) <= lockupDuration <= 86400 * 365(1 year)
// // minLockupDuration maxLockupDuration
// export async function lockStake(account: string, amount: string, toValidatorID: number, lockupDuration: number) {
//     const method = contract.methods.lockStake(toValidatorID, lockupDuration, amount)
//     const txHash = await sendContractTx(method, account, SFC_ADDRESS)
//     console.log(txHash)
// }

// export async function unlockStake(account: string, amount: string, toValidatorID: number) {
//     const method = contract.methods.unlockStake(toValidatorID, amount)
//     const txHash = await sendContractTx(method, account, SFC_ADDRESS)
//     console.log(txHash)
// }

// export async function claimRewards(account: string, toValidatorID: number) {
//     const method = contract.methods.claimRewards(toValidatorID)
//     const txHash = await sendContractTx(method, account, SFC_ADDRESS)
//     console.log(txHash)
// }

// export async function restakeRewards(account: string, toValidatorID: number) {
//     const method = contract.methods.restakeRewards(toValidatorID)
//     const txHash = await sendContractTx(method, account, SFC_ADDRESS)
//     console.log(txHash)
// }

// export async function getStake(delegator: string, toValidatorID: number) {
//     const result = await contract.methods.getStake(delegator, toValidatorID).call()
//     console.log(web3.utils.fromWei(result, "ether"))
// }

// export async function getLockedStake(delegator: string, toValidatorID: number) {
//     const result = await contract.methods.getLockedStake(delegator, toValidatorID).call()
//     console.log(web3.utils.fromWei(result, "ether"))
// }

// export async function getUnlockedStake(delegator: string, toValidatorID: number) {
//     const result = await contract.methods.getUnlockedStake(delegator, toValidatorID).call()
//     console.log(web3.utils.fromWei(result, "ether"))
// }

// export async function pendingRewards(delegator: string, toValidatorID: number) {
//     const result = await contract.methods.pendingRewards(delegator, toValidatorID).call()
//     console.log(web3.utils.fromWei(result, "ether"))
// }

// export async function isLockedUp(delegator: string, toValidatorID: number) {
//     const result = await contract.methods.isLockedUp(delegator, toValidatorID).call()
//     console.log(result)
// }

// export async function getLockupInfo(delegator: string, toValidatorID: number) {
//     const result = await contract.methods.getLockupInfo(delegator, toValidatorID).call()
//     console.log(result)
// }

// export async function sendContractTx(method: any, account: any, to: string, value?: string) {
//     return new Promise(async (resolve, reject) => {
//         try {
//             const gasEstimate = await method.estimateGas({value: value, from: account.address})
//             const nonce = await web3.eth.getTransactionCount(account.address, 'pending');
//             const gasPrice = await web3.eth.getGasPrice();
//             const maxEstimatedGas = Math.floor(gasEstimate * 1.1);

//             const tx = {
//                 from: account.address,
//                 to: to,
//                 data: method.encodeABI(),
//                 nonce: nonce,
//                 gasPrice: gasPrice,
//                 gasLimit: maxEstimatedGas,
//                 value: value ?? 0
//             }

//             const signedTx = await account.signTransaction(tx)

//             web3.eth.sendSignedTransaction(signedTx.rawTransaction)
//                 .on('transactionHash', function (hash) {
//                     const hashHex = signedTx.transactionHash;
//                     resolve(hashHex)
//                 })
//                 .on('error', function (error) {
//                     reject(error)
//                 });
//         } catch (e) {
//             reject(e)
//         }

//     })
// }
