import { DAY_DURATION } from 'constants/base'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

export function getLocalDate(utcDateStr) {
  const timeZone = dayjs.tz.guess()
  return dayjs(utcDateStr).tz(timeZone).format('YYYY-MM-DD HH:mm:ss')
}

export const delay = (time) => new Promise((resolve) => setTimeout(() => {resolve(1)}, time))

export const getDateFormat = (time) => new Date(time).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"}) 
export const getDateFormatWithWeek = (time) => new Date(time).toLocaleDateString('en-us', {weekday:"short", year:"numeric", month:"short", day:"numeric"}) 

// time in millisecond
export const getDays = (time) => {
  if (time <= 0) {
    return 'now'
  }
  return `${Math.ceil(time/1000/DAY_DURATION)} days`
}
