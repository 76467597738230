import { makeStyles, Theme } from '@material-ui/core';
import {defaultTheme} from "../../modules/theme/mainTheme";

export const useHomeStyles = makeStyles<Theme>(theme => ({
  root: {
    minHeight: '100vh',
    padding: theme.spacing(3, 20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    marginTop: theme.spacing(3.5),
    width: '100%'
  },
  tabs: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2.5),
    fontFamily: 'NeverMind-DemiBold',
    '& > div': {
      color: defaultTheme.palette.text.secondary,
      fontSize: '24px',
      fontWeight: 600,
      marginRight: theme.spacing(9),
      cursor: 'pointer',
      '& > sup': {
        fontSize: '16px',
      },
      '&.selected': {
        color: defaultTheme.palette.text.primary,
      }
    }
  },
  tableWrapper: {
    background: '#1F1F1F',
    borderRadius: '30px',
    margin: theme.spacing(7,0),
    overflow: 'hidden'
  },
  tableHeader: {
    padding: theme.spacing(4,6),
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '16px',
    fontWeight: 600,
    color: defaultTheme.palette.text.secondary,
    gridTemplateColumns: '30% 40% 20% 10%',
    background: 'rgba(0, 0, 0, 0.12)'
  },
  tableItem: {
    margin: theme.spacing(0,6),
    padding: theme.spacing(5,0),
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '14px',
    fontWeight: 500,
    color: defaultTheme.palette.text.secondary,
    gridTemplateColumns: '30% 40% 20% 10%',
    borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    '&:last-child': {
      border: 'none'
    },
  },
  textWhite: {
    fontSize: '16px',
    color: defaultTheme.palette.text.primary,
  },
  assetTableHeader: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'space-between',
    gridTemplateColumns: '30% 20% 20% 20% 10%',
    padding: theme.spacing(4,6),
    fontSize: '14px',
    fontWeight: 500,
    color: defaultTheme.palette.text.secondary,
  },
  assetTableItem: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'space-between',
    gridTemplateColumns: '30% 20% 20% 20% 10%',
    margin: theme.spacing(0,6),
    padding: theme.spacing(5,0),
    fontSize: '14px',
    fontWeight: 500,
    color: defaultTheme.palette.text.primary,
    borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    '&:last-child': {
      border: 'none'
    }
  },
  assetTableAsset: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginLeft: theme.spacing(4),
      '& > span': {
        fontSize: '14px',
        fontWeight: 600,
        color: defaultTheme.palette.text.primary,
        marginBottom: '6px'
      },
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px',
        fontWeight: 500,
        color: defaultTheme.palette.text.secondary,
      },
    }
  }
}));
