import React, {useState} from 'react'
import {useWeb3React} from "@web3-react/core";
import { BigNumber as BN } from "bignumber.js";
import {formatBalance} from "@polkadot/util";
import { NormalButton } from 'components/Button';
import ItemInfo from 'components/ItemInfo';
import { useTheStyles } from './useTheStyles';
import { skuUtil } from 'utils/sakuraUtil';
import { getDateFormatWithWeek, getDays } from 'utils/timeUtils';
import { contractConstants } from 'constants/contractConstants';
import { QueryLoading } from 'modules/common/components/QueryLoading/QueryLoading';
import {ReactComponent as HistoryIcon} from '../../../images/history.svg'
import WithdrawHistoryDialog from 'components/Dialog/WithdrawHistoryDialog';

export default function WithdrawListComponent(props) {
  const { withdrawList, validatorId, handleWithdrawSuccess } = props
  const { account, library } = useWeb3React()
  const classes = useTheStyles();
  const [loadings, setLoadings] = useState(new Array(withdrawList.length).fill(false))
  const [openWithdrawHistory, setOpenWithdrawHistory] = useState(false);

  const withdrawListData = withdrawList.map(item => {
    const amountBig = new BN(item.amount)
    const amountForDispaly = formatBalance(amountBig.toFixed(), { forceUnit: 'SKU', withSi: true }, 18).split(' ')[0].split('.')[0]
    const requestTime = new BN(item.createdTime).times(1000).toNumber()
    const undelegateTimeForDisplay = getDateFormatWithWeek(requestTime)
    const requestId = new BN(item.withdrawRequestID).toNumber()
    const withdrawDuration = parseInt(contractConstants.withdrawTime)
    const enableWithdraw = Date.now() >= requestTime + withdrawDuration * 1000
    const withdrawTimeForDisplay = item.withdrawTime ? getDateFormatWithWeek(new BN(item.withdrawTime).times(1000).toNumber()) : 
      enableWithdraw ? 'now' : `in ${getDays(requestTime + withdrawDuration * 1000 - Date.now())}`

    return {
      ...item,
      amountForDispaly,
      undelegateTimeForDisplay,
      withdrawTimeForDisplay,
      requestId,
      enableWithdraw,
      showWithdrawBtn: !item.withdrawTime,
    }
  }).filter(item => item.showWithdrawBtn)

  const setLoadingsImp = (val, index) => {
    const l = Array.from(loadings)
    l[index] = val
    setLoadings(l)
  }

  const handleWithdraw = async (val, index) => {
    setLoadingsImp(true, index)
      try {
        const txResult = await skuUtil.withdraw(account, validatorId, val.requestId, library)
        handleWithdrawSuccess()
      } catch (e) {
        console.log(e)
      } finally {
        setLoadingsImp(false, index)
      }
  }

  return (
    <div className={classes.root}>
      <div className={classes.withdrawInfoRight}>
        <div>Withdrawable</div>
        <div className={classes.historyWrapper}
          onClick={() => {
            setOpenWithdrawHistory(true)
          }}>
            <HistoryIcon width={12} className={classes.historySvg}/>
          {/* <img width={12} src={HistoryIcon} 
            alt=""
          /> */}
          <span>Undelegation History</span>
        </div>
      </div>
      <div className={classes.itemList}>
        {
          withdrawListData?.length > 0 && withdrawListData.map((item, index) => (
            <div className={classes.withdrawItemWrapper} key={`${index}_withdraw`}>
              <div className={classes.withdrawItemInfo}>
                  <ItemInfo title={'Amount (SKU)'} content={item.amountForDispaly} />
                  <ItemInfo title={'Undelegation Time'} content={item.undelegateTimeForDisplay} />
                  <ItemInfo title={'Withdrawal'} content={item.withdrawTimeForDisplay} />
                  <div className={classes.withdrawBtnWrapper}>
                    <NormalButton 
                      onClick={() => {
                        handleWithdraw(item, index)
                      }}
                      disabled={!item.enableWithdraw || loadings[index]}
                      height="32px">
                      {
                        loadings[index] ? <QueryLoading size={16} /> : 'Withdraw'
                      }
                  </NormalButton>
                </div>
              </div>
            </div>
          ))
        }
      </div>
      {openWithdrawHistory && <WithdrawHistoryDialog
          withdrawList={withdrawList.filter(item => !!item.withdrawTime)}
          open={openWithdrawHistory}
          handleClose={() => setOpenWithdrawHistory(false)}
        />}
    </div>
  )
}