import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const QrCodeHead = (props: SvgIconProps) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="15.5" stroke="white" stroke-opacity="0.12"/>
            <g clip-path="url(#clip0_1128_26841)">
                <path opacity="0.9" fill-rule="evenodd" clip-rule="evenodd" d="M8 15.2728H15.2727V8H8V15.2728ZM9.45445 9.45445H13.8181V13.8181H9.45445V9.45445ZM8 23.9999H15.2727V16.7271H8V23.9999ZM9.45445 18.1817H13.8181V22.5342H9.45445V18.1817ZM16.7271 15.2728V8H24V15.2728H16.7271ZM18.1818 13.8181H22.5454V9.45445H18.1818V13.8181Z" fill="currentColor"/>
                <path opacity="0.7" fill-rule="evenodd" clip-rule="evenodd" d="M12.3636 10.909H10.909V12.3636H12.3636V10.9089V10.909ZM21.0909 18.1817H22.5454V16.727H23.9999V21.0908H19.6361V19.6361H18.1817V23.9998H16.7271V16.727H21.0909V18.1817ZM10.909 19.6361H12.3636V21.0908H10.909V19.6361ZM19.6361 22.5454H21.091V23.9999H19.6361V22.5454ZM24 22.5454H22.5452V23.9999H24V22.5454ZM19.6361 10.909H21.091V12.3636H19.6361V10.9089V10.909Z" fill="currentColor"/>
            </g>
            <rect x="8.5" y="8.5" width="15" height="15" stroke="currentColor"/>
            <defs>
                <clipPath id="clip0_1128_26841">
                    <rect width="16" height="16" fill="currentColor" transform="translate(8 8)"/>
                </clipPath>
            </defs>
        </svg>
    );
};
