import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const HomeSendIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 33 32">
        <g clip-path="url(#clip0_1175_554)">
            <g clip-path="url(#clip1_1175_554)">
                <path d="M23.6759 7H15.3063C13.6967 7 12.9456 8.93145 14.0186 10.0045L17.2377 13.2235L7.90238 22.5589C7.36587 23.0954 7.36587 23.8465 7.90238 24.383L8.11699 24.5976C8.6535 25.1341 9.40462 25.1341 9.94113 24.5976L19.2765 15.2623L22.4955 18.4814C23.5686 19.5544 25.5 18.8033 25.5 17.1937V8.82414C25.3927 7.75112 24.6416 7 23.6759 7Z" fill="currentColor"/>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_1175_554">
                <rect width="32" height="32" fill="currentColor" transform="translate(0.5)"/>
            </clipPath>
            <clipPath id="clip1_1175_554">
                <rect width="32" height="32" fill="currentColor" transform="translate(0.5)"/>
            </clipPath>
        </defs>
    </SvgIcon>
  );
};
