import React, {useState} from 'react'
import './index.scss'
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../../images/dialog_close.svg'
import { CopyIcon } from '../../../modules/icons/CopyIcon';
import { CodeIcon } from '../../../modules/icons/CodeIcon';

import {shortenAddress} from "../../../utils/helper";
import {NormalButton, PinkButton} from "../../Button";

export default function EditWalletDialog(props) {
    const { handleClose, open, handleShowRemove, handleShowAddressCode } = props;
    const [walletName, setWalletName] = useState('Wallet1')
    const address = '0xae7eba2ef5bb8b2420728c65c081d30d84559eb1'

    return (
        <Dialog className="edit-wallet-dialog" onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
            <div className="dialog-wrapper">
                <div>
                    <div className="dialog-header">
                        <h3>Wallet Settings</h3>
                        <img width={24} height={24} src={CloseIcon} alt="" onClick={() => handleClose()}/>
                    </div>
                    <div className="dialog-content">
                        <div className="edit-wallet-item">
                            <span>Address</span>
                            <div>
                                <div>{shortenAddress(address)}</div>
                                <div>
                                    <CopyIcon/>
                                </div>
                                <div onClick={() => handleShowAddressCode()}>
                                    <CodeIcon/>
                                </div>
                            </div>
                        </div>
                        <div className="edit-wallet-item">
                            <span>Type</span>
                            <div>WalletConnect</div>
                        </div>
                        <div className="edit-wallet-name">
                            <div>Name</div>
                            <input
                                type="text"
                                value={walletName}
                                autoFocus
                                onChange={(e) => setWalletName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="dialog-footer">
                        <NormalButton onClick={() => handleShowRemove()}>Remove Wallet</NormalButton>
                        <PinkButton>Save</PinkButton>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
