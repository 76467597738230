import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const EyeIcon = (props: SvgIconProps) => {
  return (
      <SvgIcon {...props} viewBox="0 0 16 16">
          <g clip-path="url(#clip0_661_3353)">
              <path d="M12 4.8C11 3.6 9.5 3 8 3C6.5 3 5 3.6 3.9 4.8L1 7.7L3.9 10.7C5 11.9 6.4 12.5 8 12.5C9.5 12.5 11 11.9 12.1 10.7L15 7.7L12 4.8ZM11.2 9.8C10.8 10.2 10.3 10.6 9.7 10.8C9.2 11 8.6 11.2 8 11.2C7.4 11.2 6.8 11 6.2 10.8C5.7 10.6 5.2 10.2 4.7 9.8L2.7 7.7L4.7 5.7C5.6 4.8 6.7 4.3 7.9 4.3C9.1 4.3 10.2 4.8 11.1 5.7L13.1 7.7L11.2 9.8Z" fill="currentColor"/>
              <path d="M9.50002 7.80001C9.50002 8.60001 8.80002 9.30001 8.00002 9.30001C7.60002 9.30001 7.20002 9.10001 6.90002 8.90001C6.60002 8.60001 6.40002 8.20001 6.40002 7.80001C6.40002 6.90001 7.00002 6.20001 7.80002 6.20001V7.90001H9.50002V7.80001Z" fill="currentColor"/>
          </g>
          <defs>
              <clipPath id="clip0_661_3353">
                  <rect width="13.9" height="9.5" fill="white" transform="translate(1 3)"/>
              </clipPath>
          </defs>
      </SvgIcon>
  );
};
