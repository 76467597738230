import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import {defaultTheme} from "../../modules/theme/mainTheme";

const useTheStyle = makeStyles<Theme>(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
    color: defaultTheme.palette.text.secondary,
  },
  content: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    color: defaultTheme.palette.text.primary,
  }
}));

const ItemInfo = (props: any) => {
  const { title, content } = props
  const classes = useTheStyle();
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        {title}
      </div>
      <div className={classes.content}>
        {content}
      </div>
    </div>
  );
};

export default ItemInfo;