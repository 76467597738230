import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const LogoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 31 30">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.3649 18.7507C21.5015 20.8243 24.957 20.825 27.0929 18.7522C29.2287 16.6793 29.2196 13.3175 27.0914 11.2522C24.9632 9.18679 21.5247 9.18614 19.3889 11.2259C21.4907 9.1531 21.49 5.8161 19.3618 3.75072C17.2251 1.67709 13.7697 1.67643 11.6338 3.74926C9.50646 5.81383 9.49863 9.15908 11.6183 11.2327C9.48161 9.1756 6.03464 9.18321 3.90729 11.2478C1.77143 13.3206 1.77211 16.6741 3.9088 18.7478C6.04549 20.8214 9.50097 20.8221 11.6368 18.7492C13.7642 16.6847 13.772 13.3394 11.6523 11.2658C13.7805 13.3146 17.202 13.3153 19.3378 11.2755C17.2275 13.3401 17.2367 16.6853 19.3649 18.7507Z" fill="#FF7B9F"/>
        <path d="M11.6382 26.2492C13.7727 28.3207 17.2327 28.3214 19.3663 26.2507C21.4999 24.18 21.4992 20.8222 19.3648 18.7507C17.2303 16.6793 13.7704 16.6786 11.6367 18.7493C9.50312 20.8199 9.50379 24.1778 11.6382 26.2492Z" fill="#FF7B9F"/>
    </SvgIcon>
  );
};
