import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { save, load } from 'redux-localstorage-simple';

import api from './api/reducer';
import app from './app/reducer';
import transaction from './transaction/reducer';
import wallet from './wallet/reducer';
import { i18nSlice } from 'modules/i18n/i18nSlice';
import {persistStore, persistReducer} from 'redux-persist'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'

// states that are auto persisted to localstorage and reloade
const PERSISTED_KEYS: string[] = ['application', 'settings'];

const i18nPersistConfig = {
  key: 'i18n',
  storage: storage,
};

const i18n = persistReducer(i18nPersistConfig, i18nSlice.reducer);

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['language', 'darkMode', 'ethPrice', 'woofPrice']
}

const myPersistReducer = persistReducer(
    persistConfig,
    combineReducers({
      i18n,
      api,
      app,
      transaction,
      wallet
    }))
const store = createStore(myPersistReducer, applyMiddleware(thunkMiddleware))

// const store = configureStore({
//   reducer: { i18n, api, app, transaction, home },
//   middleware: [
//     ...getDefaultMiddleware({ thunk: false }),
//     save({ states: PERSISTED_KEYS }),
//   ],
//   preloadedState: load({ states: PERSISTED_KEYS }),
// });

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;
