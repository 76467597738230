import React from 'react'
import './index.scss'
import Dialog from '@material-ui/core/Dialog';
import SuccessIcon from '../../../images/success_icon.svg'
import FailIcon from '../../../images/fail_icon.svg'
import {PinkButton} from "../../Button";

export default function SuccessDialog(props) {
    const { handleClose, open, title, content, fail } = props;

    return (
        <Dialog className="send-dialog" onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
            <div className="dialog-wrapper">
                <div>
                    <div className="dialog-content">
                        <div className="success-content">
                            <img width={60} src={fail ? FailIcon : SuccessIcon} alt=""/>
                            <h3>{title}</h3>
                            <span>{content}</span>
                        </div>
                    </div>
                    <div className="dialog-footer">
                        <PinkButton
                            width="100px"
                            onClick={() => handleClose()}
                        > Close </PinkButton>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
