import { makeStyles, Theme } from '@material-ui/core';
import HomeBg from '../../images/home_bg.svg'

export const usefirstStyles = makeStyles<Theme>(theme => ({
  home: {
    minHeight: '100vh',
    paddingLeft: 200,
    // background: `url(${HomeBg})`,
    background: '#000',
  },
  sideNav: {
    // background: 'linear-gradient(225.45deg, rgba(111, 119, 189, 0.22) 4.48%, rgba(111, 119, 189, 0.1) 107.71%)',
    boxShadow: '1px 0px 0px rgba(255, 255, 255, 0.12)',
    width: 200,
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
  },
  tabs: {
    marginTop: theme.spacing(6),
  },
  tab: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    marginTop: '60px!important',
    borderRight: '1px solid transparent',
    lineHeight: '20px',
    transition: 'color 0.3s',
    '& svg': {
      width: 32,
      height: 32,
      marginBottom: 12,
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  active: {
    color: theme.palette.primary.main,
  },
  copyright: {
    padding: theme.spacing(0, 5),
    marginBottom: theme.spacing(4.5),
  },
  footer: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    '& svg': {
      width: 20,
      height: 20,
    },
    '& p': {
      fontWeight: '400!important',
    },
  },
}));
