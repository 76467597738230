import _ from "lodash";

export const WALLETLIST = 'walletList';
export const CURRENTWALLET = 'currentWallet';
export const TRANSACTIONS = 'transactions';

export const setLocalWallets = (wallet: any) => {
    let wallets: any = localStorage.getItem(WALLETLIST)
    wallets = JSON.parse(wallets??'[]');
    if (_.find(wallets, item => item.address === wallet.address)) {
        return
    }
    wallets.push(wallet)
    localStorage.setItem(WALLETLIST, JSON.stringify(wallets))
}

export const getLocalWallets = () => {
    let wallets: any = localStorage.getItem(WALLETLIST)
    wallets = JSON.parse(wallets??'[]');
    return wallets
}

export const setCurrentWallet = (wallet: any) => {
    localStorage.setItem(CURRENTWALLET, JSON.stringify(wallet))
}

export const getCurrentWallet = () => {
    let wallet: any = localStorage.getItem(CURRENTWALLET)
    wallet = JSON.parse(wallet??'{}');
    return wallet
}
