import { makeStyles, Theme } from '@material-ui/core';
import { Flex } from 'rebass';
import {defaultTheme} from "../../modules/theme/mainTheme";

export const useStakingStyles = makeStyles<Theme>(theme => ({
  root: {
    minHeight: '100vh',
    padding: theme.spacing(7.5, 20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    marginTop: theme.spacing(17.5),
    width: '100%'
  },
  title: {
    fontSize: '42px',
    fontWeight: 500,
    textAlign: 'center',
    color: defaultTheme.palette.text.primary,
  },
  tabs: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'NeverMind-DemiBold',
    marginBottom: theme.spacing(6),
    '& > div': {
      color: defaultTheme.palette.text.secondary,
      fontSize: '24px',
      fontWeight: 600,
      marginRight: theme.spacing(9),
      cursor: 'pointer',
      '& > sup': {
        fontSize: '16px',
      },
      '&.selected': {
        color: defaultTheme.palette.text.primary,
      }
    }
  },
  tableWrapper: {
    background: '#1F1F1F',
    boxShadow: '3px 3px 40px rgba(0, 0, 0, 0.2)',
    borderRadius: '30px',
    margin: theme.spacing(7,0),
    overflow: 'hidden'
  },
  tableHeader: {
    padding: theme.spacing(4,6),
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '16px',
    fontWeight: 600,
    color: defaultTheme.palette.text.secondary,
    gridTemplateColumns: '20% 10% 20% 20% 15% 15%',
    background: 'rgba(0, 0, 0, 0.12)'
  },
  tableItem: {
    margin: theme.spacing(0,6),
    padding: theme.spacing(5,0),
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '16px',
    fontWeight: 600,
    color: defaultTheme.palette.text.primary,
    gridTemplateColumns: '20% 10% 20% 20% 15% 15%',
    borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    '&:last-child': {
      border: 'none'
    }
  },
  tableItemName: {
    display: 'flex',
    alignItems: 'center',
    '&>img': {
      marginRight: '8px'
    }
  },
  delegationWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  delegationNone: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    background: '#1F1F1F',
    boxShadow: '3px 3px 40px rgba(0, 0, 0, 0.2)',
    borderRadius: '12px',
    padding: '24px 0',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF'
  },
  delegationItem: {
    width: '100%',
    background: '#1F1F1F',
    boxShadow: '3px 3px 40px rgba(0, 0, 0, 0.2)',
    borderRadius: '30px',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    marginBottom: '18px'
  },
  delegationInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  delegationItemLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    fontSize: '16px',
    fontWeight: 600,
    color: defaultTheme.palette.text.primary,
    padding: '0 30px',
    marginRight: '10px',
    '&>img': {
      marginBottom: '16px'
    }
  },
  delegationItemRight: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  delegationItemRightTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '50px',
    '&>div': {
      display: 'flex',
      flexDirection: 'column',
      '&>span': {
        fontSize: '12px',
        color: defaultTheme.palette.text.secondary,
      },
      '&>div': {
        fontSize: '14px',
        fontWeight: 600,
        marginTop: '4px',
        color: defaultTheme.palette.text.primary,
      }
    }
  },
  delegationItemRightBtns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '&>button': {
      width: '23%',
      marginLeft: '10px',
      '&:first-child': {
        margin: 0
      }
    }
  },
  withdrawListWrapper: {
    marginTop: '24px',
    background: 'rgba(255, 255, 255, 0.05)',
    borderRadius: '12px',
    padding: '0 24px 20px 24px'
  }
}));
