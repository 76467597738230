import React, { useEffect, useState } from 'react';
import {usefirstStyles} from './useFirstStyles';
import { TextLogo } from 'components/TextLogo/TextLogo';
import { Typography } from '@material-ui/core';
import { tHTML } from 'modules/i18n/intl';
import classNames from 'classnames';
import { HomeIcon } from 'modules/icons/HomeIcon';
import { HomeSendIcon } from 'modules/icons/HomeSendIcon';
import { StakingIcon } from 'modules/icons/StakingIcon';
import { BridgeIcon } from 'modules/icons/BridgeIcon';
import { Switch, useHistory} from 'react-router-dom';
import ConnectWalletPage from '../connectWallet'
import HomeComponent from '../home'
import SendComponent from '../send'
import StakingComponent from '../staking'
import BridgeComponent from '../bridge'
import ReceiveComponent from '../receive'
import _ from 'lodash';
import {Route} from "react-router";
import ConnectWalletDialog from "../../components/Dialog/ConnectWalletDialog";
import EditWalletDialog from "../../components/Dialog/WalletSetDialog";
import RemoveWalletDialog from "../../components/Dialog/RemoveWalletDialog";
import SelectWalletDialog from "../../components/Dialog/SelectWalletDialog";
import AddressCodeDialog from "../../components/Dialog/AddressCodeDialog";
import {useWeb3React} from "@web3-react/core";
import { changeWalletNetwork } from 'connectors';
import { SAKURA_NETWORK } from 'constants/base';
import { Toaster } from 'react-hot-toast';

export default function FirstPage(props) {
  const classes = usefirstStyles();
  const { account, active, library } = useWeb3React()
  const history = useHistory()
  const [openConnectWallet, setOpenConnectWallet] = useState(false)
  const [openEditWallet, setOpenEditWallet] = useState(false)
  const [openRemoveWallet, setOpenRemoveWallet] = useState(false)
  const [openSelectWallet, setOpenSelectWallet] = useState(false)
  const [openAddressCode, setOpenAddressCode] = useState(false)
  const [isWalletPage, setIsWalletPage] = useState(true)

  const tabs = [
    {
      icon: <HomeIcon />,
      name: 'Home',
      path: `${props.match.url}home`,
    },
    {
      icon: <HomeSendIcon />,
      name: 'Send',
      path: `${props.match.url}send`,
    },
    {
      icon: <BridgeIcon />,
      name: 'Bridge',
      path: `${props.match.url}bridge`,
    },
    {
      icon: <StakingIcon />,
      name: 'Staking',
      path: `${props.match.url}staking`,
    },
  ];
  const [selectTab, setSelectTab] = useState(0);

  useEffect(() => {
    if (account && active && library) {
      setSelectTab(0);
      setIsWalletPage(false)
      changeWalletNetwork(library, SAKURA_NETWORK, library.chainId)
      history.push('/home')
    } else {
      setIsWalletPage(true)
    }
  }, [account, active, library]);

  useEffect(() => {
    if (_.includes(window.location.href, '/home')) {
      setSelectTab(0);
    } else if (_.includes(window.location.href, '/bridge')) {
      setSelectTab(1);
    } else if (_.includes(window.location.href, '/staking')) {
      setSelectTab(2);
    }
  }, [setSelectTab]);

  useEffect(() => {
    setIsWalletPage(true)
    history.push('/connectWallet')
  }, []);

  const handleChangeTab = index => {
    setSelectTab(index);
    history.push(tabs[index].path)
  };

  return (
    <div className={classes.home} style={{paddingLeft: isWalletPage ? '0' : '200px'}}>
      {isWalletPage && <TextLogo
          style={{
            justifyContent: 'flex-start',
            paddingLeft: '40px'
          }}
      />}
      <Toaster />
      <Switch>
        <Route
          exact
          strict
          path={`${props.match.url}connectWallet`}
          render={(routeProps) => (
              <ConnectWalletPage
                  {...routeProps}
                  onOpenConnectWallet={() => setOpenConnectWallet(true)}
                  onOpenEditWallet={() => setOpenEditWallet(true)}
              />
          )}
        />
        <Route
          exact
          strict
          path={`${props.match.url}home`}
          render={(routeProps) => (
              <HomeComponent
                  {...routeProps}
                  onOpenConnectWallet={() => setOpenConnectWallet(true)}
                  onOpenAddressCode={() => setOpenAddressCode(true)}
              />
          )}
        />
        <Route
          exact
          strict
          path={`${props.match.url}staking`}
          render={(routeProps) => (
              <StakingComponent
                  {...routeProps}
                  onOpenConnectWallet={() => setOpenConnectWallet(true)}
                  onOpenAddressCode={() => setOpenAddressCode(true)}
              />
          )}
        />
        <Route
          exact
          strict
          path={`${props.match.url}send`}
          render={(routeProps) => (
              <SendComponent
                  {...routeProps}
                  onOpenConnectWallet={() => setOpenConnectWallet(true)}
                  onOpenAddressCode={() => setOpenAddressCode(true)}
              />
          )}
        />
        <Route
          exact
          strict
          path={`${props.match.url}bridge`}
          render={(routeProps) => (
              <BridgeComponent
                  {...routeProps}
                  onOpenConnectWallet={() => setOpenConnectWallet(true)}
                  onOpenAddressCode={() => setOpenAddressCode(true)}
              />
          )}
        />
        <Route
          exact
          strict
          path={`${props.match.url}receive`}
          render={(routeProps) => (
              <ReceiveComponent
                  {...routeProps}
                  onOpenConnectWallet={() => setOpenConnectWallet(true)}
                  onOpenAddressCode={() => setOpenAddressCode(true)}
              />
          )}
        />
      </Switch>
      {!isWalletPage && (
          <div className={classes.sideNav}>
            <TextLogo/>
            <div className={classes.tabs}>
              {tabs.map((tab, index) => (
                  <Typography
                      variant="body1"
                      component="div"
                      key={tab.name}
                      onClick={() => {
                        handleChangeTab(index);
                        // history.push(tab.path);
                      }}
                      className={classNames(
                          classes.tab,
                          selectTab === index && classes.active,
                      )}
                  >
                    {tab.icon}
                    <span>{tab.name}</span>
                  </Typography>
              ))}
            </div>
            <div className={classes.footer}>
              <Typography variant="body1" className={classes.copyright}>
                {tHTML('footer.content')}
              </Typography>
            </div>
          </div>
      )}
      {openConnectWallet && <ConnectWalletDialog
          open={openConnectWallet}
          handleClose={() => setOpenConnectWallet(false)}
      />}
      {openEditWallet && <EditWalletDialog
          open={openEditWallet}
          handleClose={() => setOpenEditWallet(false)}
          handleShowAddressCode={() => setOpenAddressCode(true)}
          handleShowRemove={() => {
            setOpenEditWallet(false)
            setOpenRemoveWallet(true)
          }}
      />}
      {openRemoveWallet && <RemoveWalletDialog
          open={openRemoveWallet}
          handleClose={() => setOpenRemoveWallet(false)}
      />}
      {openSelectWallet && <SelectWalletDialog
          open={openSelectWallet}
          handleClose={() => setOpenSelectWallet(false)}
          onOpenEditWallet={() => setOpenEditWallet(true)}
      />}
      {openAddressCode && <AddressCodeDialog
          open={openAddressCode}
          handleClose={() => setOpenAddressCode(false)}/>}
    </div>
  );
}
