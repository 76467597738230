import {useEffect, useState} from 'react'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import './index.scss'
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../../images/dialog_close.svg'
import BackIcon from "../../../images/arrow_left.svg";
import LoadingIcon from "../../../images/loading.svg";
import {SAKURA_NETWORK, SUPPORTED_WALLETS, WALLET_NAME_CLV, WALLET_NAME_METAMASK, WALLET_NAME_WC} from 'constants/base'
import _ from "lodash";
import {setCurrentWallet, setLocalWallets} from "../../../utils/LocalstorageUtils";
import { changeWalletNetwork } from 'connectors';
import toast from 'react-hot-toast';

export default function ConnectWalletDialog(props) {
    const { handleClose, open } = props;
    const [selectedWallet, setSelectedWallet] = useState({});
    const { account, active, activate } = useWeb3React()

    useEffect(() => {
      if (selectedWallet.connector) {
        if (selectedWallet.name === WALLET_NAME_METAMASK && (!window.ethereum || !window.ethereum.isMetaMask)) {
          window.open(selectedWallet.downloadUrl)
          closeModal()
          return
        }

        if (selectedWallet.name === WALLET_NAME_CLV && !window.clover) {
          closeModal()
          window.open(selectedWallet.downloadUrl)
          return
        }
        connectWallet()
      }
    }, [selectedWallet])

    useEffect(() => {
      if (selectedWallet.connector && active) {
        closeModal()
      }
    }, [active, selectedWallet])

    const closeModal = () => {
      setSelectedWallet({})
      handleClose()
    }

    const onSuccess = () => {
      const obj = {
        name: selectedWallet.name,
        walletType: 'WalletConnect',
        address: account,
        balance: 0
      }
      setLocalWallets(obj)
      setCurrentWallet(obj)
    }

    const connectWallet = async () => {
      if (selectedWallet.connector) {
        try {
          await activate(selectedWallet.connector, undefined, true)
          onSuccess()
        } catch (error) {
          if (error instanceof UnsupportedChainIdError) {
            if (selectedWallet.name === WALLET_NAME_WC) {
              toast('Please switch to sakura chain')
            } else {
              selectedWallet?.connector?.getProvider().then((provider) => {
                if (provider) {
                  changeWalletNetwork(provider, SAKURA_NETWORK, provider.chainId)
                  .then(async () => {
                    try {
                      await activate(selectedWallet.connector, undefined, true)
                      onSuccess()
                    } catch(e) {
                      toast('Please switch to sakura chain')
                    }
                  })
                  .catch(e => {
                    toast('Please switch to sakura chain')
                  })
                }
              })
            }
          }
        }
      }
    }

    return (
        <Dialog className="connect-wallet-dialog" onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
            <div className="dialog-wrapper">
                <div>
                    <div className="dialog-header">
                        {_.isEmpty(selectedWallet) ? <h3>Connect Wallet</h3> :
                            <img width={24} height={24} src={BackIcon} alt="" onClick={() => setSelectedWallet({})}/>}
                        <img width={24} height={24} src={CloseIcon} alt="" onClick={() => closeModal()}/>
                    </div>
                    <div className="dialog-content">
                        {_.isEmpty(selectedWallet) ? (
                            <div>
                                {
                                    _.map(SUPPORTED_WALLETS, (item, index) => (
                                        <div
                                            className="wallet-item"
                                            key={`${item.name}_${index}`}
                                            onClick={() => {
                                                setSelectedWallet(item)
                                            }}
                                        >
                                            <img width={32} src={item.icon} alt=""/>
                                            <span>{item.name}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        ) : (
                            <div className="wallet-loading">
                                <div className="wallet-loading-top">
                                    <img width={24} src={selectedWallet.icon} alt=""/>
                                    <span>{selectedWallet.name}</span>
                                </div>
                                <div className="wallet-loading-bottom">
                                    <img width={70} src={LoadingIcon} alt=""/>
                                    <span>Initializing...</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
