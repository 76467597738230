import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BridgeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 33 32">
        <g clip-path="url(#clip0_1456_770)">
            <g clip-path="url(#clip1_1456_770)">
                <path d="M17.8333 10.6665V21.3332C17.8333 22.394 17.4119 23.4115 16.6618 24.1616C15.9116 24.9117 14.8942 25.3332 13.8333 25.3332H10.94C10.6255 26.2232 10.0064 26.9733 9.19226 27.451C8.37811 27.9287 7.4213 28.1032 6.49095 27.9436C5.56059 27.784 4.71661 27.3007 4.10817 26.579C3.49973 25.8573 3.16602 24.9438 3.16602 23.9998C3.16602 23.0559 3.49973 22.1424 4.10817 21.4207C4.71661 20.699 5.56059 20.2157 6.49095 20.0561C7.4213 19.8965 8.37811 20.071 9.19226 20.5487C10.0064 21.0264 10.6255 21.7765 10.94 22.6665H13.8333C14.187 22.6665 14.5261 22.526 14.7761 22.276C15.0262 22.0259 15.1667 21.6868 15.1667 21.3332V10.6665C15.1667 9.60564 15.5881 8.58822 16.3382 7.83808C17.0884 7.08793 18.1058 6.6665 19.1667 6.6665H23.1667V2.6665L29.8333 7.99984L23.1667 13.3332V9.33317H19.1667C18.813 9.33317 18.4739 9.47365 18.2239 9.7237C17.9738 9.97374 17.8333 10.3129 17.8333 10.6665Z" fill="currentColor"/>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_1456_770">
                <rect width="32" height="32" fill="currentColor" transform="translate(0.5)"/>
            </clipPath>
            <clipPath id="clip1_1456_770">
                <rect width="32" height="32" fill="currentColor" transform="translate(0.5)"/>
            </clipPath>
        </defs>
    </SvgIcon>
  );
};
