import Web3 from "web3";
import { delay } from "./timeUtils";
import { ChainInfo, SAKURA_NETWORK } from "constants/base";
import { changeWalletNetwork } from "connectors";

const abi = require("human-standard-token-abi")

class SakuraSendUtil {
  web3: any
  contract: any

  async setWeb3AndContract (library: any, selectedNetwork: ChainInfo) {
    if (!library) {
      throw new Error('No wallet connected!')
    }
    if (parseInt(library.chainId) !== parseInt(selectedNetwork.chainId)) {
      await changeWalletNetwork(library, selectedNetwork, library.chainId)
    }
    this.web3 = new Web3(library)
    if (selectedNetwork.chainId !== SAKURA_NETWORK.chainId) {
      this.contract = new this.web3.eth.Contract(abi, selectedNetwork.tokenAddress)
    }
  }

  async send(fromAddress: string, toAddress: string, amount: string, selectedNetwork: ChainInfo, library: any) {
    await this.setWeb3AndContract(library, selectedNetwork)
    let receipt
    if (selectedNetwork.chainId !== SAKURA_NETWORK.chainId) {
      receipt = await this.contract.methods.transfer(toAddress, this.web3.utils.toWei(amount)).send({ from: fromAddress })
    } else {
      receipt = await this.web3.eth.sendTransaction({ from: fromAddress, to: toAddress, value: this.web3.utils.toWei(amount)})
    }

    const txStatus = await this.checkTxStatus(receipt.transactionHash) 
    return {
      txStatus,
      txHash: receipt.transactionHash
    }
  }

  async checkTxStatus(txHash: string) {
    if (!this.web3) {
      throw new Error('Invalid web3 for checking tx status!')
    }

    try {
      while(true) {
        const receipt = await this.web3.eth.getTransactionReceipt(txHash)
        if (receipt?.status) {
          return true
        }
        await delay(3000)
      }
    } catch(e) {
      return false
    }
  }
}

export const skuSendUtil = new SakuraSendUtil()
