import React, {useState} from 'react'
import './index.scss'
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../../images/dialog_close.svg'
import BackIcon from '../../../images/back_icon.svg'
import SuccessIcon from '../../../images/success_icon.svg'
import {InputField} from "../../InputField/InputField";
import Checkbox from '@material-ui/core/Checkbox';
import { PrivateKeyIcon } from '../../../modules/icons/PrivateKeyIcon'
import { KeyStoreIcon } from '../../../modules/icons/KeyStoreIcon'
import { MnemonicIcon } from '../../../modules/icons/MnemonicIcon'
import { UploadIcon } from '../../../modules/icons/UploadIcon'
import {NormalButton, PinkButton} from "../../Button";
import { PointComponent } from '../CreateWalletDialog'
import {div} from "../../../types/bigNum";

export default function RestoreWalletDialog(props) {
    const { handleClose, open } = props;
    const restoreTypes = [
        {
            type: 'Keystore',
            icon: <KeyStoreIcon/>,
            steps: 2
        },
        {
            type: 'Mnemonic',
            icon: <MnemonicIcon/>,
            steps: 3
        },
        {
            type: 'Private Key',
            icon: <PrivateKeyIcon/>,
            steps: 3
        },
    ]
    const [step, setStep] = useState(0)
    const [selectType, setSelectType] = useState(restoreTypes[0])
    const [password, setPassword] = useState('')
    const [walletPassword, setWalletPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const handleWalletPasswordChange = (value) => {
        setWalletPassword(value);
    };
    const handlePasswordChange = (value) => {
        setPassword(value);
    };
    const handleConfirmPasswordChange = (value) => {
        setConfirmPassword(value);
    };

    return (
        <Dialog className="restore-wallet-dialog" onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
            <div className="dialog-wrapper">
                <div>
                    <div className="dialog-header">
                        {(step + 1) !== selectType.steps && step !== 0 ? <img
                            width={24}
                            height={24}
                            src={BackIcon}
                            alt=""
                            onClick={() => {
                                if (step > 0) {
                                    setStep(step - 1)
                                }
                            }}
                        />: <div style={{width: '24px'}}></div>}
                        <div className="restore-wallet-step-wrapper">
                            <PointComponent step={step} total={selectType.steps}/>
                        </div>
                        <img width={24} height={24} src={CloseIcon} alt="" onClick={() => handleClose()}/>
                    </div>
                    {step === 0 && (
                        <div className="dialog-content-wrapper">
                            <div className="dialog-content">
                                <div className="restore-wallet-header">Restore wallet</div>
                                <div className="restore-wallet-top">
                                    {restoreTypes.map((item, index) => (
                                        <div
                                            key={`${item.type}`}
                                            className={selectType.type === item.type ? 'restore-home-top-item selected' : "restore-home-top-item"}
                                            onClick={() => setSelectType(item)}
                                        >
                                            {item.icon}
                                            <span>{item.type}</span>
                                        </div>
                                    ))}
                                </div>
                                <NormalButton margin='0 0 32px 0'>
                                    <UploadIcon/>
                                    Upload keystore file
                                </NormalButton>
                                <InputField
                                    label='Enter your wallet password'
                                    value={walletPassword}
                                    placeHolder=''
                                    type="password"
                                    onChange={(e) => handleWalletPasswordChange(e.target.value)}
                                />
                            </div>
                            <div className="dialog-footer">
                                <PinkButton
                                    margin="-32px 0 0 0"
                                    onClick={() => setStep(1)}
                                >  Unlock wallet </PinkButton>
                            </div>
                        </div>
                    )}
                    {step !== 0 && (step + 1) !== selectType.steps && (
                        <div className="dialog-content-wrapper">
                            <div className="dialog-content">
                                <div className="restore-wallet-header">Set password for your wallet</div>
                                <InputField
                                    label='Set a password'
                                    value={password}
                                    placeHolder=''
                                    type="password"
                                    appendContent={(<span>Make sure to enter at least 8 and max 200 characters, including one upper-case letter, a symbol and a number</span>)}
                                    onChange={(e) => handlePasswordChange(e.target.value)}
                                />
                                <InputField
                                    label='Re-enter password'
                                    value={confirmPassword}
                                    placeHolder=''
                                    type="password"
                                    appendContent={(<div className="agree-wrapper">
                                        <Checkbox
                                            checked={checked}
                                            onChange={handleChange}
                                            color="default"
                                            inputProps={{ 'aria-label': 'checkbox with default color' }}
                                        />
                                        <span>I understand that I will need both the keystore file and the password to access my wallet. Once I have downloaded the file below, I will safely store it as well as the password</span>
                                    </div>)}
                                    onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                                />
                            </div>
                            <div className="dialog-footer">
                                <PinkButton
                                    disabled={!checked}
                                    onClick={() => setStep(2)}
                                > Continue </PinkButton>
                            </div>
                        </div>
                    )}
                    {(step + 1) === selectType.steps && (
                        <div>
                            <div className="dialog-content">
                                <div className="success-content">
                                    <img width={60} src={SuccessIcon} alt=""/>
                                    <h3>You're all set!</h3>
                                    <span>You have successfully created your wallet</span>
                                </div>
                            </div>
                            <div className="dialog-footer">
                                <NormalButton
                                    onClick={() => handleClose()}
                                > Access your wallet </NormalButton>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Dialog>
    )
}
