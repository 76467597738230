import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CopyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <g clip-path="url(#clip0_596_1610)">
            <g clip-path="url(#clip1_596_1610)">
                <path d="M14.5781 24.0005H5.625C3.55719 24.0005 1.875 22.3183 1.875 20.2505V7.54736C1.875 5.47955 3.55719 3.79736 5.625 3.79736H14.5781C16.6459 3.79736 18.3281 5.47955 18.3281 7.54736V20.2505C18.3281 22.3183 16.6459 24.0005 14.5781 24.0005ZM5.625 5.67236C4.59119 5.67236 3.75 6.51355 3.75 7.54736V20.2505C3.75 21.2843 4.59119 22.1255 5.625 22.1255H14.5781C15.6119 22.1255 16.4531 21.2843 16.4531 20.2505V7.54736C16.4531 6.51355 15.6119 5.67236 14.5781 5.67236H5.625ZM22.0781 17.9067V3.75049C22.0781 1.68268 20.3959 0.000488281 18.3281 0.000488281H7.92188C7.40405 0.000488281 6.98438 0.420166 6.98438 0.937988C6.98438 1.45581 7.40405 1.87549 7.92188 1.87549H18.3281C19.3619 1.87549 20.2031 2.71667 20.2031 3.75049V17.9067C20.2031 18.4246 20.6228 18.8442 21.1406 18.8442C21.6584 18.8442 22.0781 18.4246 22.0781 17.9067Z" fill="currentColor"/>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_596_1610">
                <rect width="24" height="24" fill="currentColor" transform="translate(0 0.000488281)"/>
            </clipPath>
            <clipPath id="clip1_596_1610">
                <rect width="24" height="24" fill="currentColor" transform="translate(0 0.000488281)"/>
            </clipPath>
        </defs>
    </SvgIcon>
  );
};
