import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import './index.scss'
import { BigNumber as BN } from "bignumber.js";
import Dialog from '@material-ui/core/Dialog';
import {formatBalance} from "@polkadot/util";
import CloseIcon from '../../../images/dialog_close.svg';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { getDateFormatWithWeek } from 'utils/timeUtils';

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    borderBottom: '1px solid rgba(255,255,255,0.12)'
  },
  body: {
    fontSize: 14,
    borderBottom: '1px solid rgba(255,255,255,0.12)'
  },
}))(TableCell);

export default function WithdrawHistoryDialog(props) {
    const { handleClose, open, withdrawList } = props;

    const withdrawListData = withdrawList.map(item => {
      const amountBig = new BN(item.amount)
      const amountForDispaly = formatBalance(amountBig.toFixed(), { forceUnit: 'SKU', withSi: true }, 18).split(' ')[0].split('.')[0]
      const requestTime = new BN(item.createdTime).times(1000).toNumber()
      const undelegateTimeForDisplay = getDateFormatWithWeek(requestTime)
      const withdrawTimeForDisplay = getDateFormatWithWeek(new BN(item.withdrawTime).times(1000).toNumber())

      return {
        amountForDispaly,
        undelegateTimeForDisplay,
        withdrawTimeForDisplay
      }
    })
    return (
        <Dialog className="history-dialog" onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
            <div className="dialog-wrapper">
              <div>
                <div className="dialog-header">
                  <h3>Undelegation History</h3>
                  <img width={24} height={24} src={CloseIcon} alt="" onClick={() => handleClose()}/>
                </div>
              </div>
              <div className="dialog-content-wrapper">
                <div className="dialog-content">
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Amount (SKU)</StyledTableCell>
                          <StyledTableCell align="right">Undelelgation Time</StyledTableCell>
                          <StyledTableCell align="right">Withdraw</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {withdrawListData.map((row, index) => (
                          <TableRow key={`hist_${index}`}>
                            <StyledTableCell component="th" scope="row">
                              {row.amountForDispaly}
                            </StyledTableCell>
                            <StyledTableCell align="right">{row.undelegateTimeForDisplay}</StyledTableCell>
                            <StyledTableCell align="right">{row.withdrawTimeForDisplay}</StyledTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                </div>
              </div>
            </div>
        </Dialog>
    )
}
