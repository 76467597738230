import { makeStyles, Theme, Typography } from '@material-ui/core';
import { t } from 'modules/i18n/intl';
import { LogoIcon } from 'modules/icons/logo/LogoIcon';

const useTextLogoStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(9.5, 0, 0, 0),
    height: 24,
    boxSizing: 'content-box',
  },
  logoIcon: {
    width: 30,
    height: 30,
    color: theme.palette.primary.main,
  },
  clover: {
    marginLeft: theme.spacing(3),
    color: theme.palette.text.primary
  },
}));

export const TextLogo = ({style}: any) => {
  const classes = useTextLogoStyles();

  return (
    <div className={classes.root} style={style}>
      <LogoIcon className={classes.logoIcon} />
      <Typography variant="h2" className={classes.clover}>
        {t('sakura')}
      </Typography>
    </div>
  );
};
