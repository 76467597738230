import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import RemindIcon from '../../images/remind_icon.svg'

const useRemindStyle = makeStyles<Theme>(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start!important',
    width: '100%',
    background: 'rgba(255, 0, 70, 0.2)',
    borderRadius: '12px',
    padding: '14px 18px',
    '& > img': {

    }
  },
  remindContent: {
    color: '#FFFFFF',
    fontSize: '16px',
    marginLeft: '14px'
  },
}));

const Remind = (props: any) => {
  const { content } = props
  const classes = useRemindStyle();
  return (
      <div className={classes.wrapper}>
        <img width={20} src={RemindIcon} alt=""/>
        <div className={classes.remindContent}>
          {content}
        </div>
      </div>
  );
};

export default Remind;
