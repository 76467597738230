import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const KeyStoreIcon = (props: SvgIconProps) => {
  return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.1001 17.4001C11.1001 17.8001 11.4001 18.2001 11.9001 18.2001H16.2001C16.6001 18.2001 17.0001 17.9001 17.0001 17.4001C17.0001 17.0001 16.7001 16.6001 16.2001 16.6001H11.9001C11.5001 16.6001 11.1001 17.0001 11.1001 17.4001Z" fill="currentColor"/>
          <path d="M7.2001 18.0001C7.5001 18.3001 8.0001 18.3001 8.3001 18.0001L11.3001 15.0001C11.6001 14.7 11.6001 14.2 11.3001 13.9L8.3001 10.8001C8.0001 10.4001 7.5001 10.4 7.2001 10.7C6.9001 11 6.9001 11.5001 7.2001 11.8001L9.7001 14.3001L7.2001 16.8001C6.9001 17.2001 6.9001 17.7 7.2001 18.0001Z" fill="currentColor"/>
          <path d="M3.9 1.9C4.4 1.3 5.2 1 6 1H14C14.3 1 14.5 1.1 14.7 1.3L20.7 7.3C20.9 7.5 21 7.7 21 8V20C21 20.8 20.7 21.6 20.1 22.1C19.6 22.7 18.8 23 18 23H6C5.2 23 4.4 22.7 3.9 22.1C3.3 21.6 3 20.8 3 20V4C3 3.2 3.3 2.4 3.9 1.9ZM6 3C5.7 3 5.5 3.1 5.3 3.3C5.1 3.5 5 3.7 5 4V20C5 20.3 5.1 20.5 5.3 20.7C5.5 20.9 5.7 21 6 21H18C18.3 21 18.5 20.9 18.7 20.7C18.9 20.5 19 20.3 19 20V8.4L13.6 3H6Z" fill="currentColor"/>
          <path d="M14 1C14.6 1 15 1.4 15 2V7H20C20.6 7 21 7.4 21 8C21 8.6 20.6 9 20 9H14C13.4 9 13 8.6 13 8V2C13 1.4 13.4 1 14 1Z" fill="currentColor"/>
      </svg>
  );
};
