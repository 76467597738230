import { useState, useMemo } from 'react';
import { BigNumber as BN } from "bignumber.js";
import {useWeb3React} from "@web3-react/core";
import { useQuery } from '@apollo/client';
import { useHomeStyles } from './useHomeStyles';
import {t} from "../../modules/i18n/intl";
import {TopContent} from "../connectWallet";
import SendIcon from "../../images/send_icon.svg";
import ReceiveIcon from "../../images/receive_icon.svg";
import CopyIcon from "../../images/copy_icon.svg";
import CloverIcon from '../../images/clover.svg'
import Header from "../../components/Header/Header";
import {NormalButton} from "../../components/Button";
import {useHistory} from "react-router-dom";
import SendDialog from "../../components/Dialog/SendDialog";
import { useAccountSkuBalanceByChain } from 'hooks/useAccountBalance';
import { usePrices } from 'hooks/usePrices';
import { format, formatWithFp } from 'utils/balanceUtils';
import { SAKURA_NETWORK } from 'constants/base';
import { FETCH_DELEGATIONS_BY_ADDRESS } from 'utils/subgraph';
import {getColorByStatus} from "../../utils/helper";

export default function HomeComponent(props) {
    const { onOpenAddressCode, onOpenConnectWallet } = props
    const history = useHistory()
    const classes = useHomeStyles();
    const [selectTab, setSelectTab] = useState(0);
    const [openSendDialog, setOpenSendDialog] = useState(false);
    const balanceObj = useAccountSkuBalanceByChain(SAKURA_NETWORK)
    const {priceLoading, skuPrice} = usePrices()
    const { account } = useWeb3React()

    const AccountDelegations = useQuery(FETCH_DELEGATIONS_BY_ADDRESS, {
      variables: {address: account},
      fetchPolicy: 'cache-and-network',
      pollInterval: 15 * 1000
    });

    const getTotalDelegatedAmount = (data) => {
      if (data) {
        let totalAmount = new BN(0)
        for (let item of data) {
          totalAmount = totalAmount.plus(new BN(item.amount))
        }

        return totalAmount.toFixed(0)
      }

      return '0'
    }

    const memAvail = useMemo(() => {
      return {
        amount: `${balanceObj.formatedBalance} SKU`,
        price: (balanceObj.balanceLoading || priceLoading) ? '$0' : `$${formatWithFp(new BN(balanceObj.balance).times(skuPrice).toFixed(0))}`
      }
    }, [balanceObj, priceLoading, skuPrice]);

    const totalDelegatedAmount = useMemo(() => getTotalDelegatedAmount(AccountDelegations?.data?.delegationsByAddress?.edges?.map(item => item.delegation)),
      [AccountDelegations])

    const memTotal = useMemo(() => {
      const totalAmount = new BN(balanceObj.balance).plus(new BN(totalDelegatedAmount))
      return {
        amount: `${formatWithFp(totalAmount.toFixed(0))} SKU`,
        price: (balanceObj.balanceLoading || priceLoading) ? '$0' : `$${formatWithFp(new BN(totalAmount).times(skuPrice).toFixed(0))}`,
      }
    }, [balanceObj, priceLoading, skuPrice, totalDelegatedAmount])

    const leftContent = [
        {
            title: 'Available',
            price: memAvail.price,
            amount: memAvail.amount
        },
        {
            title: 'Total',
            price: memTotal.price,
            amount: memTotal.amount
        }
    ]
    const rightContent = [
        {
            icon: SendIcon,
            text: 'Send',
            onClick: () => history.push('/bridge')
        }, {
            icon: ReceiveIcon,
            text: 'Receive',
            onClick: () => history.push('/receive')
        }
    ]
  return (
    <div className={classes.root}>
      <Header title="Home" onOpenConnectWallet={onOpenConnectWallet} onOpenAddressCode={onOpenAddressCode} />
      <div className={classes.content}>
          <TopContent
              leftContent={leftContent}
              rightContent={rightContent}
          />
          <div>
              <div className={classes.tabs}>
                  <div className={selectTab === 0 ? 'selected' : ''} onClick={() => setSelectTab(0)}>Transactions <sup>4</sup></div>
                  <div className={selectTab === 1 ? 'selected' : ''} onClick={() => setSelectTab(1)}>Tokens <sup>0</sup></div>
              </div>
              <div className={classes.tableWrapper}>
                  {selectTab === 0 ? (
                      <div>
                          <div className={classes.tableHeader}>
                              <span>Time</span>
                              <span>Address</span>
                              <span>Amount(SKU)</span>
                              <span style={{textAlign: 'right'}}>Status</span>
                          </div>
                          <div className={classes.tableItem}>
                              <span>April 24, 2022, 3:14 PM</span>
                              <span className={classes.textWhite}>0xfc00face000000000000000...00000</span>
                              <span className={classes.textWhite}>- 1.00</span>
                              <span style={{textAlign: 'right', color: getColorByStatus('success')}}>Success</span>
                          </div>
                          <div className={classes.tableItem}>
                              <span>April 24, 2022, 3:14 PM</span>
                              <span className={classes.textWhite}>0xfc00face000000000000000...00000</span>
                              <span className={classes.textWhite}>- 1.00</span>
                              <span style={{textAlign: 'right', color: getColorByStatus('success')}}>Success</span>
                          </div>
                          <div className={classes.tableItem}>
                              <span>April 24, 2022, 3:14 PM</span>
                              <span className={classes.textWhite}>0xfc00face000000000000000...00000</span>
                              <span className={classes.textWhite}>- 1.00</span>
                              <span style={{textAlign: 'right', color: getColorByStatus('success')}}>Success</span>
                          </div>
                      </div>
                  ) : (
                      <div>
                          <div className={classes.assetTableHeader}>
                              <span>Asset</span>
                              <span>Available</span>
                              <span>Deposited</span>
                              <span>Borrowed</span>
                              <span style={{textAlign: 'right'}}>Action</span>
                          </div>
                          <div className={classes.assetTableItem}>
                              <div className={classes.assetTableAsset}>
                                  <img width={40} src={CloverIcon} alt=""/>
                                  <div>
                                      <span>CLV</span>
                                      <div>5d20b...df45 <img width={16} src={CopyIcon} alt=""/></div>
                                  </div>
                              </div>
                              <span>7.45</span>
                              <span>_</span>
                              <span>_</span>
                              <div className={classes.assetTableAction}>
                                  <NormalButton onClick={() => setOpenSendDialog(true)} height="32px">Send</NormalButton>
                              </div>
                          </div>
                          <div className={classes.assetTableItem}>
                              <div className={classes.assetTableAsset}>
                                  <img width={40} src={CloverIcon} alt=""/>
                                  <div>
                                      <span>CLV</span>
                                      <div>5d20b...df45 <img width={16} src={CopyIcon} alt=""/></div>
                                  </div>
                              </div>
                              <span>7.45</span>
                              <span>_</span>
                              <span>_</span>
                              <div className={classes.assetTableAction}>
                                  <NormalButton onClick={() => setOpenSendDialog(true)} height="32px">Send</NormalButton>
                              </div>
                          </div>
                          <div className={classes.assetTableItem}>
                              <div className={classes.assetTableAsset}>
                                  <img width={40} src={CloverIcon} alt=""/>
                                  <div>
                                      <span>CLV</span>
                                      <div>5d20b...df45 <img width={16} src={CopyIcon} alt=""/></div>
                                  </div>
                              </div>
                              <span>7.45</span>
                              <span>_</span>
                              <span>_</span>
                              <div className={classes.assetTableAction}>
                                  <NormalButton onClick={() => setOpenSendDialog(true)} height="32px">Send</NormalButton>
                              </div>
                          </div>
                      </div>
                  )}
              </div>
          </div>
      </div>
        {openSendDialog && <SendDialog
            open={openSendDialog}
            currentStep={0}
            handleClose={() => setOpenSendDialog(false)}
        />}
    </div>
  );
}
