import React, {useState} from 'react'
import './index.scss'
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../../images/dialog_close.svg'
import {PinkButton} from "../../Button";
import Remind from "../../Remind";
import {InputField} from "../../InputField/InputField";
import {useWeb3React} from "@web3-react/core";
import {div} from "../../../types/bigNum";
import BackIcon from "../../../images/back_icon.svg";
import {getCurrentWallet} from "../../../utils/LocalstorageUtils";
import { skuUtil } from 'utils/sakuraUtil';
import { useBtnChild } from 'hooks/useBtnChild';
import { useAccountSkuBalanceByChain } from 'hooks/useAccountBalance';
import { SAKURA_NETWORK } from 'constants/base';

export default function UnDelegateDialog(props) {
    const { handleClose, open, handleSuccess, delegation } = props;
    const { account, library } = useWeb3React()
    const [isContinue, setIsContinue] = useState(false);
    const [loading, setLoading] = useState(false)
    const currentWallet = getCurrentWallet()

    const btnChild = useBtnChild(loading, 'Undelegate')

    // const handleAmountChange = (val) => {
    //     setAmount(val)
    // }

    const balanceObj = useAccountSkuBalanceByChain(SAKURA_NETWORK)
    const handleUndelegate = async () => {
      setLoading(true)
        try {
          const txResult = await skuUtil.undelegate(account, delegation.amountDelegated, delegation.validatorId, delegation.withdrawRequests.length, library)
            handleClose()
            handleSuccess('Undelegation Successful', txResult.txHash)
        } catch (e) {
            console.log(e)
        } finally {
          setLoading(false)
        }
    }
    return (
        <Dialog className="unDelegate-dialog" onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
            <div className="dialog-wrapper">
                {!isContinue ? (
                    <div>
                        <div className="dialog-header">
                            <h3>Undelegate SKU</h3>
                            <img width={24} height={24} src={CloseIcon} alt="" onClick={() => handleClose()}/>
                        </div>
                        <div className="dialog-content">
                            <div className="unDelegate-remind">
                                <Remind content="The withdrawal of your delegated tokens will take 7 days" />
                            </div>
                            {
                              delegation.isDelegationLocked &&
                              <div className="unDelegate-locked-remind">
                                <Remind content="Your delegation is still locked. You will lose part of your rewards by undelegating before the lock expiration." />
                              </div>
                            }

                            {/* <InputField
                                label='Amount'
                                value={amount}
                                placeHolder=''
                                type="text"
                                rightContent={<span style={{color: '#888686'}}> Entire Delegation </span>}
                                onChange={(e) => handleAmountChange(e.target.value)}
                            /> */}
                        </div>
                        <div className="dialog-footer">
                            <PinkButton
                                margin="-32px 0 0"
                                onClick={() => setIsContinue(true)}
                            >Undelegate</PinkButton>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="dialog-header">
                            <img
                                width={24}
                                height={24}
                                src={BackIcon}
                                alt=""
                                onClick={() => {
                                    setIsContinue(false)
                                }}
                            />
                            <h3>Undelegate SKU - Confirmation</h3>
                            <img width={24} height={24} src={CloseIcon} alt="" onClick={() => handleClose()}/>
                        </div>
                        <div className="dialog-content-wrapper">
                            <div className="dialog-content">
                                <div className="dialog-content-row">
                                    <span>Validator</span>
                                    <div>{delegation.validatorName}</div>
                                </div>
                                <div className="dialog-content-row">
                                    <span>From</span>
                                    <div>{account} ( {balanceObj.formatedBalance} SKU, {currentWallet.name} )</div>
                                </div>
                                <div className="dialog-content-row">
                                    <span>Undelegate Amount</span>
                                    <div>{delegation.delegatedAmount} SKU</div>
                                </div>
                                {/* <InputField
                                    label='Please enter your wallet password to bridge the transaction'
                                    value={walletPassword}
                                    placeHolder=''
                                    type="password"
                                    appendContent='Max Fee: 0.0141784271184 SKU'
                                    // onChange={(e) => handlePasswordChange(e.target.value)}
                                /> */}
                            </div>
                            <div className="dialog-footer" style={{marginTop: '0'}}>
                                <PinkButton
                                  disabled={loading}
                                  onClick={() => handleUndelegate()}
                                >{btnChild}</PinkButton>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Dialog>
    )
}
